import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "col-12 col-md-8" }
const _hoisted_3 = { class: "question-content-mb-3" }
const _hoisted_4 = {
  key: 0,
  class: "button-group"
}
const _hoisted_5 = {
  key: 1,
  class: "error q-py-md"
}
const _hoisted_6 = {
  key: 2,
  class: "row flex justify-between margin-top-5"
}
const _hoisted_7 = { class: "row justify-start" }
const _hoisted_8 = { class: "row justify-end" }
const _hoisted_9 = {
  key: 3,
  class: "row flex justify-between margin-top-5"
}
const _hoisted_10 = { class: "row justify-start" }
const _hoisted_11 = { class: "row justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuestionHeader = _resolveComponent("QuestionHeader")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { padding: "" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_3, [
              (_ctx.attributes && _ctx.attributes.questionHead)
                ? (_openBlock(), _createBlock(_component_QuestionHeader, {
                    key: 0,
                    content: _ctx.attributes.questionHead[0].content,
                    headline: _ctx.attributes.questionHead[0].headline
                  }, null, 8, ["content", "headline"]))
                : _createCommentVNode("", true)
            ]),
            (_ctx.attributes && _ctx.attributes.choiseButtons)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["q-mr-lg button-choise", { active: _ctx.rents[0].paysRent === true }]),
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setRent(true)))
                  }, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.attributes.choiseButtons[0].buttonLeftText), 1)
                  ], 2),
                  _createElementVNode("div", {
                    class: _normalizeClass(["button-choise", { active: !_ctx.rents[0].paysRent }]),
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setRent(false)))
                  }, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.attributes.choiseButtons[0].buttonRightText), 1)
                  ], 2)
                ]))
              : _createCommentVNode("", true),
            (_ctx.rents[0].paysRent === 'initial' && _ctx.validate)
              ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.attributes.errorMessageLivingSituation), 1))
              : _createCommentVNode("", true),
            (!_ctx.editMode)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    (_ctx.attributes && _ctx.attributes.navigationButtons)
                      ? (_openBlock(), _createBlock(_component_q_btn, {
                          key: 0,
                          onClick: _ctx.onButtonBack,
                          outline: "",
                          color: "primary",
                          label: _ctx.attributes.navigationButtons.backButtonText
                        }, null, 8, ["onClick", "label"]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_8, [
                    (_ctx.attributes && _ctx.attributes.navigationButtons)
                      ? (_openBlock(), _createBlock(_component_q_btn, {
                          key: 0,
                          onClick: _ctx.onButtonNext,
                          color: "accent",
                          label: _ctx.attributes.navigationButtons.nextButtonText
                        }, null, 8, ["onClick", "label"]))
                      : _createCommentVNode("", true)
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.editMode)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    (_ctx.attributes && _ctx.attributes.navigationButtons)
                      ? (_openBlock(), _createBlock(_component_q_btn, {
                          key: 0,
                          onClick: _ctx.cancelEdit,
                          outline: "",
                          color: "primary",
                          label: _ctx.attributes.navigationButtons.CancelButtonText
                        }, null, 8, ["onClick", "label"]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    (_ctx.attributes && _ctx.attributes.navigationButtons)
                      ? (_openBlock(), _createBlock(_component_q_btn, {
                          key: 0,
                          onClick: _ctx.onButtonNext,
                          color: "accent",
                          label: 
                  _ctx.rents[0].paysRent
                    ? _ctx.attributes.navigationButtons.nextButtonText
                    : _ctx.attributes.navigationButtons.SaveButtonText
                
                        }, null, 8, ["onClick", "label"]))
                      : _createCommentVNode("", true)
                  ])
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ]),
    _: 1
  }))
}