
import { useCurrencyInput } from "vue-currency-input";
import { watch } from "vue";

export default {
  name: "QCurrencyInput",
  props: {
    modelValue: Number,
  },
  data() {
    return {
      value: 0,
    };
  },
  setup(props) {
    const options = {
      locale: "de-DE",
      currency: "EUR",
      currencyDisplay: "symbol" as any,
      hideCurrencySymbolOnFocus: true,
      hideGroupingSeparatorOnFocus: false,
      hideNegligibleDecimalDigitsOnFocus: true,
      autoDecimalDigits: false,
      precision: 2,
      autoSign: true,
      useGrouping: true,
      accountingSign: false,
    };
    const { inputRef, setValue } = useCurrencyInput(options);
    watch(
      () => props.modelValue,
      (value) => {
        setValue(value);
      }
    );

    return { inputRef };
  },
};
