import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from "./quasar-user-options";
import "./styles/index.scss";
import BackendClient from "./api/ApiClientBackend";
import JwtService from "./api/JwtService";

BackendClient.interceptors.response.use(
  (response) => {
    if (response.status === 200 || response.status === 201) {
      return Promise.resolve(response);
    }
  },
  async (error) => {
    store.commit("setToken", null);
    JwtService.destroyToken();
    await store.dispatch("init");
    return Promise.reject(error.response);
  }
);

createApp(App)
  .use(Quasar, quasarUserOptions)
  .use(store)
  .use(router)
  .mount("#app");
