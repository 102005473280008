import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "col-12 col-md-8" }
const _hoisted_3 = { class: "question-content-mb-3" }
const _hoisted_4 = {
  class: "q-gutter-md margin-top-3",
  style: {"max-width":"500px"}
}
const _hoisted_5 = {
  key: 0,
  class: "row flex justify-between margin-top-5"
}
const _hoisted_6 = { class: "row justify-start" }
const _hoisted_7 = { class: "row justify-end" }
const _hoisted_8 = {
  key: 1,
  class: "row flex justify-between margin-top-5"
}
const _hoisted_9 = { class: "row justify-start" }
const _hoisted_10 = { class: "row justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuestionHeader = _resolveComponent("QuestionHeader")!
  const _component_QCurrencyInput = _resolveComponent("QCurrencyInput")!
  const _component_q_field = _resolveComponent("q-field")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_form = _resolveComponent("q-form")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { padding: "" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_3, [
              (_ctx.attributes && _ctx.attributes.questionHead)
                ? (_openBlock(), _createBlock(_component_QuestionHeader, {
                    key: 0,
                    content: _ctx.attributes.questionHead[2].content,
                    headline: _ctx.attributes.questionHead[2].headline
                  }, null, 8, ["content", "headline"]))
                : _createCommentVNode("", true)
            ]),
            _createVNode(_component_q_form, {
              ref: "form",
              greedy: "",
              onSubmit: _withModifiers(_ctx.onButtonNext, ["prevent"])
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_q_field, {
                    class: "col-8 col-md-12",
                    filled: "",
                    modelValue: _ctx.rents[0].monthlyRent,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.rents[0].monthlyRent) = $event)),
                    label: _ctx.attributes.montlyRentLabel + ' *',
                    "stack-label": "",
                    rules: [(v) => !!v || _ctx.requiredFieldText]
                  }, {
                    control: _withCtx(() => [
                      _createVNode(_component_QCurrencyInput, {
                        modelValue: _ctx.rents[0].monthlyRent,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.rents[0].monthlyRent) = $event)),
                        class: "q-field__input"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }, 8, ["modelValue", "label", "rules"])
                ]),
                (!_ctx.editMode)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createElementVNode("div", _hoisted_6, [
                        (_ctx.attributes && _ctx.attributes.navigationButtons)
                          ? (_openBlock(), _createBlock(_component_q_btn, {
                              key: 0,
                              onClick: _ctx.onButtonBack,
                              outline: "",
                              color: "primary",
                              label: _ctx.attributes.navigationButtons.backButtonText
                            }, null, 8, ["onClick", "label"]))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_7, [
                        (_ctx.attributes && _ctx.attributes.navigationButtons)
                          ? (_openBlock(), _createBlock(_component_q_btn, {
                              key: 0,
                              type: "submit",
                              color: "accent",
                              label: _ctx.attributes.navigationButtons.nextButtonText
                            }, null, 8, ["label"]))
                          : _createCommentVNode("", true)
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.editMode)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createElementVNode("div", _hoisted_9, [
                        (_ctx.attributes && _ctx.attributes.navigationButtons)
                          ? (_openBlock(), _createBlock(_component_q_btn, {
                              key: 0,
                              onClick: _ctx.onButtonBack,
                              outline: "",
                              color: "primary",
                              label: _ctx.attributes.navigationButtons.backButtonText
                            }, null, 8, ["onClick", "label"]))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_10, [
                        (_ctx.attributes && _ctx.attributes.navigationButtons)
                          ? (_openBlock(), _createBlock(_component_q_btn, {
                              key: 0,
                              type: "submit",
                              color: "accent",
                              label: _ctx.attributes.navigationButtons.SaveButtonText
                            }, null, 8, ["label"]))
                          : _createCommentVNode("", true)
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["onSubmit"])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}