
import { defineComponent } from "vue";
import StrapiService from "@/api/StrapiService";

export default defineComponent({
  data() {
    return {
      attributes: [],
      imprintUrl: "",
      dataProtectionUrl: "",
    };
  },
  created() {
    this.getConfigContent();
  },
  methods: {
    async getConfigContent(): Promise<void> {
      const content = await StrapiService.getConfigContent();
      const attributes = content.attributes;
      this.attributes = attributes;
      this.imprintUrl = attributes.imprintUrl;
      this.dataProtectionUrl = attributes.dataProtectionUrl;
    },
  },
});
