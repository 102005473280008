import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container q-mb-xl" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_btn_group = _resolveComponent("q-btn-group")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.hasProgress)
      ? (_openBlock(), _createBlock(_component_q_btn_group, {
          key: 0,
          rounded: "",
          class: "progress-bar q-mb-lg"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_btn, {
              label: _ctx.$q.screen.gt.sm ? _ctx.attributes.progressTitle1 : '1',
              class: "col-grow",
              "no-caps": "",
              color: _ctx.progress === '1' ? 'accent' : 'grey-2',
              "text-color": _ctx.progress === '1' ? 'grey-2' : 'grey-8',
              disable: _ctx.progress !== '1'
            }, null, 8, ["label", "color", "text-color", "disable"]),
            _createVNode(_component_q_separator, { vertical: "" }),
            _createVNode(_component_q_btn, {
              label: _ctx.$q.screen.gt.sm ? _ctx.attributes.progressTitle2 : '2',
              class: "col-grow",
              "no-caps": "",
              color: _ctx.progress === '2' ? 'accent' : 'grey-2',
              "text-color": _ctx.progress === '2' ? 'grey-2' : 'grey-8',
              disable: _ctx.progress !== '2'
            }, null, 8, ["label", "color", "text-color", "disable"]),
            _createVNode(_component_q_separator, { vertical: "" }),
            _createVNode(_component_q_btn, {
              label: _ctx.$q.screen.gt.sm ? _ctx.attributes.progressTitle3 : '3',
              class: "col-grow",
              "no-caps": "",
              color: _ctx.progress === '3' ? 'accent' : 'grey-2',
              "text-color": _ctx.progress === '3' ? 'grey-2' : 'grey-8',
              disable: _ctx.progress !== '3'
            }, null, 8, ["label", "color", "text-color", "disable"]),
            _createVNode(_component_q_separator, { vertical: "" }),
            _createVNode(_component_q_btn, {
              label: _ctx.$q.screen.gt.sm ? _ctx.attributes.progressTitle4 : '4',
              class: "col-grow",
              "no-caps": "",
              color: _ctx.progress === '4' ? 'accent' : 'grey-2',
              "text-color": _ctx.progress === '4' ? 'grey-2' : 'grey-8',
              disable: _ctx.progress !== '4'
            }, null, 8, ["label", "color", "text-color", "disable"]),
            _createVNode(_component_q_separator, { vertical: "" }),
            _createVNode(_component_q_btn, {
              label: _ctx.$q.screen.gt.sm ? _ctx.attributes.progressTitle5 : '5',
              class: "col-grow",
              "no-caps": "",
              color: _ctx.progress === '5' ? 'accent' : 'grey-2',
              "text-color": _ctx.progress === '5' ? 'grey-2' : 'grey-8',
              disable: _ctx.progress !== '5'
            }, null, 8, ["label", "color", "text-color", "disable"]),
            _createVNode(_component_q_separator, { vertical: "" }),
            _createVNode(_component_q_btn, {
              label: _ctx.$q.screen.gt.sm ? _ctx.attributes.progressTitle6 : '6',
              class: "col-grow",
              "no-caps": "",
              color: _ctx.progress === '6' ? 'accent' : 'grey-2',
              "text-color": _ctx.progress === '6' ? 'grey-2' : 'grey-8',
              disable: _ctx.progress !== '6'
            }, null, 8, ["label", "color", "text-color", "disable"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}