import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "col-12 col-md-8" }
const _hoisted_3 = { class: "question-content-mb-3" }
const _hoisted_4 = { class: "max-width-500" }
const _hoisted_5 = { class: "margin-top-3" }
const _hoisted_6 = { class: "margin-top-3" }
const _hoisted_7 = {
  key: 0,
  class: "margin-top-3"
}
const _hoisted_8 = { class: "row q-col-gutter-sm q-pt-md" }
const _hoisted_9 = { class: "row flex justify-end" }
const _hoisted_10 = { class: "row justify-end" }
const _hoisted_11 = {
  key: 1,
  class: "row flex justify-between margin-top-5"
}
const _hoisted_12 = { class: "row justify-start" }
const _hoisted_13 = { class: "row justify-end" }
const _hoisted_14 = {
  key: 2,
  class: "row flex justify-between margin-top-5"
}
const _hoisted_15 = { class: "row justify-start" }
const _hoisted_16 = { class: "row justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuestionHeader = _resolveComponent("QuestionHeader")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_QCurrencyInput = _resolveComponent("QCurrencyInput")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_field = _resolveComponent("q-field")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_form = _resolveComponent("q-form")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { padding: "" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_3, [
              (_ctx.attributes && _ctx.attributes.questionHead)
                ? (_openBlock(), _createBlock(_component_QuestionHeader, {
                    key: 0,
                    content: _ctx.attributes.questionHead.content,
                    headline: _ctx.attributes.questionHead.headline
                  }, null, 8, ["content", "headline"]))
                : _createCommentVNode("", true)
            ]),
            _createVNode(_component_q_form, {
              ref: "form",
              greedy: "",
              onSubmit: _withModifiers(_ctx.onButtonNext, ["prevent"])
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                  (_ctx.employmentOptions)
                    ? (_openBlock(), _createBlock(_component_q_select, {
                        key: 0,
                        filled: "",
                        modelValue: _ctx.persons[0].employment,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.persons[0].employment) = $event)),
                        options: _ctx.employmentOptions,
                        label: _ctx.attributes.employmentLabel + ' *',
                        "stack-label": "",
                        rules: [
                  (employmentOptions) =>
                    employmentOptions !== '-- Bitte wählen --' ||
                    _ctx.requiredFieldText,
                ]
                      }, null, 8, ["modelValue", "options", "label", "rules"]))
                    : _createCommentVNode("", true),
                  _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.attributes.incomeText), 1),
                  _createVNode(_component_q_field, {
                    filled: "",
                    modelValue: _ctx.incomes[0].income,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.incomes[0].income) = $event)),
                    label: _ctx.attributes.incomeLabel + ' *',
                    "stack-label": "",
                    rules: [(v) => !!v || _ctx.requiredFieldText]
                  }, {
                    control: _withCtx(() => [
                      _createVNode(_component_QCurrencyInput, {
                        modelValue: _ctx.incomes[0].income,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.incomes[0].income) = $event)),
                        class: "q-field__input"
                      }, null, 8, ["modelValue"])
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_q_icon, {
                        size: "20px",
                        name: "fas fa-info-circle",
                        class: "q-ml-sm"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_tooltip, { class: "bg-primary" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.attributes.tooltipIncome), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue", "label", "rules"]),
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("label", null, [
                      _withDirectives(_createElementVNode("input", {
                        class: "larger-checkbox",
                        type: "checkbox",
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.incomes[0].hasExtraIncome) = $event))
                      }, null, 512), [
                        [_vModelCheckbox, _ctx.incomes[0].hasExtraIncome]
                      ]),
                      _createElementVNode("span", {
                        class: "q-ml-md checkbox-span",
                        style: _normalizeStyle({ 'font-size': '1.25rem', color: '#444' })
                      }, _toDisplayString(_ctx.attributes.checkboxFurtherIncome), 5)
                    ])
                  ])
                ]),
                (_ctx.incomes[0].hasExtraIncome)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.incomes[0].listExtraIncome, (extra, index) => {
                        return (_openBlock(), _createElementBlock("div", { key: index }, [
                          _createElementVNode("div", _hoisted_8, [
                            (_ctx.extraIncomeOptions)
                              ? (_openBlock(), _createBlock(_component_q_select, {
                                  key: 0,
                                  class: "col-12 col-md-5",
                                  filled: "",
                                  modelValue: extra.extraSource,
                                  "onUpdate:modelValue": ($event: any) => ((extra.extraSource) = $event),
                                  options: _ctx.extraIncomeOptions,
                                  label: _ctx.attributes.incomeSourceLabel,
                                  "stack-label": "",
                                  rules: [
                      (extraIncomeOptions) =>
                        extraIncomeOptions !== '-- Bitte wählen --' ||
                        _ctx.requiredFieldText,
                    ]
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "label", "rules"]))
                              : _createCommentVNode("", true),
                            _createVNode(_component_q_field, {
                              class: "col-12 col-md-6",
                              filled: "",
                              modelValue: extra.extraIncome,
                              "onUpdate:modelValue": ($event: any) => ((extra.extraIncome) = $event),
                              label: _ctx.attributes.furtherMonthlyIncomeLabel + ' *',
                              "stack-label": "",
                              rules: [(v) => !!v || _ctx.requiredFieldText]
                            }, {
                              control: _withCtx(() => [
                                _createVNode(_component_QCurrencyInput, {
                                  modelValue: extra.extraIncome,
                                  "onUpdate:modelValue": ($event: any) => ((extra.extraIncome) = $event),
                                  class: "q-field__input"
                                }, null, 8, ["modelValue", "onUpdate:modelValue"])
                              ]),
                              _: 2
                            }, 1032, ["modelValue", "onUpdate:modelValue", "label", "rules"]),
                            _createElementVNode("div", _hoisted_9, [
                              _createVNode(_component_q_btn, {
                                class: "col-12 col-md-1 q-ml-md",
                                round: "",
                                style: { height: '56px', width: '56px' },
                                color: "accent",
                                icon: "fas fa-minus",
                                onClick: ($event: any) => (_ctx.deleteRow(index))
                              }, null, 8, ["onClick"])
                            ])
                          ])
                        ]))
                      }), 128)),
                      _createElementVNode("div", _hoisted_10, [
                        _createVNode(_component_q_btn, {
                          class: "q-mr-xs",
                          round: "",
                          style: { height: '56px', width: '56px' },
                          color: "primary",
                          icon: "fas fa-plus",
                          onClick: _ctx.addRow
                        }, null, 8, ["onClick"])
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (!_ctx.editMode)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                      _createElementVNode("div", _hoisted_12, [
                        (_ctx.attributes && _ctx.attributes.navigationButtons)
                          ? (_openBlock(), _createBlock(_component_q_btn, {
                              key: 0,
                              onClick: _ctx.onButtonBack,
                              outline: "",
                              color: "primary",
                              label: _ctx.attributes.navigationButtons[0].backButtonText
                            }, null, 8, ["onClick", "label"]))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_13, [
                        (_ctx.attributes && _ctx.attributes.navigationButtons)
                          ? (_openBlock(), _createBlock(_component_q_btn, {
                              key: 0,
                              type: "submit",
                              color: "accent",
                              label: _ctx.attributes.navigationButtons[0].nextButtonText
                            }, null, 8, ["label"]))
                          : _createCommentVNode("", true)
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.editMode)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                      _createElementVNode("div", _hoisted_15, [
                        (_ctx.attributes && _ctx.attributes.navigationButtons)
                          ? (_openBlock(), _createBlock(_component_q_btn, {
                              key: 0,
                              type: "submit",
                              outline: "",
                              color: "primary",
                              label: _ctx.attributes.navigationButtons[0].CancelButtonText
                            }, null, 8, ["label"]))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_16, [
                        (_ctx.attributes && _ctx.attributes.navigationButtons)
                          ? (_openBlock(), _createBlock(_component_q_btn, {
                              key: 0,
                              type: "submit",
                              color: "accent",
                              label: _ctx.attributes.navigationButtons[0].SaveButtonText
                            }, null, 8, ["label"]))
                          : _createCommentVNode("", true)
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["onSubmit"])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}