import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "col-12 col-md-8" }
const _hoisted_3 = { class: "question-content-mb-3" }
const _hoisted_4 = { class: "flex" }
const _hoisted_5 = { class: "margin-top-3" }
const _hoisted_6 = { class: "flex" }
const _hoisted_7 = {
  key: 0,
  class: "error q-py-md"
}
const _hoisted_8 = { class: "margin-top-3" }
const _hoisted_9 = { class: "flex" }
const _hoisted_10 = {
  key: 1,
  class: "row flex justify-between margin-top-5"
}
const _hoisted_11 = { class: "row justify-start" }
const _hoisted_12 = { class: "row justify-end" }
const _hoisted_13 = {
  key: 2,
  class: "row flex justify-between margin-top-5"
}
const _hoisted_14 = { class: "row justify-start" }
const _hoisted_15 = { class: "row justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuestionHeader = _resolveComponent("QuestionHeader")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { padding: "" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_3, [
              (_ctx.attributes && _ctx.attributes.questionHead)
                ? (_openBlock(), _createBlock(_component_QuestionHeader, {
                    key: 0,
                    content: _ctx.attributes.questionHead.content,
                    headline: _ctx.attributes.questionHead.headline
                  }, null, 8, ["content", "headline"]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_q_btn, {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.numberPersons > 1 ? _ctx.numberPersons-- : _ctx.numberPersons)),
                color: "primary",
                label: _ctx.attributes.minusLabel
              }, null, 8, ["label"]),
              _createVNode(_component_q_input, {
                class: "number-count",
                filled: "",
                modelValue: _ctx.numberPersons,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.numberPersons) = $event)),
                "stack-label": "",
                style: {"max-width":"50px"}
              }, null, 8, ["modelValue"]),
              _createVNode(_component_q_btn, {
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.numberPersons++)),
                color: "primary",
                label: _ctx.attributes.plusLabel
              }, null, 8, ["label"])
            ]),
            _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.attributes.childrenCountLabel), 1),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_q_btn, {
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.numberChildren > 0 ? _ctx.numberChildren-- : _ctx.numberChildren)),
                color: "primary",
                label: _ctx.attributes.minusLabel
              }, null, 8, ["label"]),
              _createVNode(_component_q_input, {
                class: "number-count",
                filled: "",
                modelValue: _ctx.numberChildren,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.numberChildren) = $event)),
                "stack-label": "",
                style: {"max-width":"50px"}
              }, null, 8, ["modelValue"]),
              _createVNode(_component_q_btn, {
                onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.numberChildren++)),
                color: "primary",
                label: _ctx.attributes.plusLabel
              }, null, 8, ["label"])
            ]),
            (_ctx.numberPersons - _ctx.numberChildren < 1 && _ctx.validate)
              ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.attributes.errorMessage), 1))
              : _createCommentVNode("", true),
            _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.attributes.carCountLabel), 1),
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_q_btn, {
                onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.numberCars > 0 ? _ctx.numberCars-- : _ctx.numberCars)),
                color: "primary",
                label: _ctx.attributes.minusLabel
              }, null, 8, ["label"]),
              _createVNode(_component_q_input, {
                class: "number-count",
                filled: "",
                modelValue: _ctx.numberCars,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.numberCars) = $event)),
                "stack-label": "",
                style: {"max-width":"50px"}
              }, null, 8, ["modelValue"]),
              _createVNode(_component_q_btn, {
                onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.numberCars++)),
                color: "primary",
                label: _ctx.attributes.plusLabel
              }, null, 8, ["label"])
            ]),
            (!_ctx.editMode)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    (_ctx.attributes && _ctx.attributes.navigationButtons)
                      ? (_openBlock(), _createBlock(_component_q_btn, {
                          key: 0,
                          onClick: _ctx.onButtonBack,
                          outline: "",
                          color: "primary",
                          label: _ctx.attributes.navigationButtons.backButtonText
                        }, null, 8, ["onClick", "label"]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_12, [
                    (_ctx.attributes && _ctx.attributes.navigationButtons)
                      ? (_openBlock(), _createBlock(_component_q_btn, {
                          key: 0,
                          onClick: _ctx.onButtonNext,
                          color: "accent",
                          label: _ctx.attributes.navigationButtons.nextButtonText
                        }, null, 8, ["onClick", "label"]))
                      : _createCommentVNode("", true)
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.editMode)
              ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                  _createElementVNode("div", _hoisted_14, [
                    (_ctx.attributes && _ctx.attributes.navigationButtons)
                      ? (_openBlock(), _createBlock(_component_q_btn, {
                          key: 0,
                          onClick: _ctx.onButtonNext,
                          outline: "",
                          color: "primary",
                          label: _ctx.attributes.navigationButtons.CancelButtonText
                        }, null, 8, ["onClick", "label"]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_15, [
                    (_ctx.attributes && _ctx.attributes.navigationButtons)
                      ? (_openBlock(), _createBlock(_component_q_btn, {
                          key: 0,
                          onClick: _ctx.onButtonNext,
                          color: "accent",
                          label: _ctx.attributes.navigationButtons.SaveButtonText
                        }, null, 8, ["onClick", "label"]))
                      : _createCommentVNode("", true)
                  ])
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ]),
    _: 1
  }))
}