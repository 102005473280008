
import { defineComponent } from "vue";
import StrapiService from "@/api/StrapiService";
import QCurrencyInput from "@/components/QCurrencyInput.vue";
import BackendService from "@/api/BackendService";

export default defineComponent({
  components: {
    QCurrencyInput,
  },
  data() {
    return {
      living: [],
      household: [],
      paysRent: "initial",
      continuesRenting: "initial",
      monthlyRent: 0,
      numberPersons: 1,
      numberCars: 0,
      numberChildren: 0,
      yesLabel: "",
      noLabel: "",
    };
  },
  async created() {
    this.getContent();
    await this.initSummary();
  },
  methods: {
    async getContent(): Promise<void> {
      const livingContent = await StrapiService.getLivingSituationContent();
      this.living = livingContent.attributes;

      const household = await StrapiService.getSummaryContent();
      this.household = household.attributes.household[0];

      this.yesLabel = household.attributes.yesLabel;
      this.noLabel = household.attributes.noLabel;
    },
    async initSummary() {
      try {
        const session = await BackendService.getSession();
        const summary = session.session_data;
        this.paysRent = summary.rents[0].paysRent;
        this.continuesRenting = summary.rents[0].continuesRenting;
        this.monthlyRent = summary.rents[0].monthlyRent;
        this.numberPersons = summary.numberPersons;
        this.numberChildren = summary.numberChildren;
        this.numberCars = summary.numberCars;
      } catch {
        this.$router.push({ path: "error-page" });
      }
    },
    paysRentLabel() {
      if (this.living && this.living.choiseButtons) {
        if (this.paysRent === true) {
          return this.living.choiseButtons[0].buttonLeftText;
        } else {
          return this.living.choiseButtons[0].buttonRigthText;
        }
      }
    },
    continuesRentingLabel() {
      if (this.living && this.living.choiseButtons) {
        if (this.continuesRenting === true) {
          return this.living.choiseButtons[1].buttonRightText;
        } else {
          return this.living.choiseButtons[1].buttonLeftText;
        }
      }
    },
    editLivingSituation() {
      this.$router.push({ name: "living-situation", params: { edit: true } });
    },
    editHousehold() {
      this.$router.push({ name: "household", params: { edit: true } });
    },
  },
});
