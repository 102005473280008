import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "row justify-end margin-top-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { padding: "" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h1", null, _toDisplayString(_ctx.attributes.headline), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.attributes.content), 1)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_q_btn, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.backToStart())),
            size: _ctx.$q.screen.gt.sm ? 'md' : 'sm',
            color: "accent",
            label: _ctx.attributes.backButtonLabel
          }, null, 8, ["size", "label"])
        ])
      ])
    ]),
    _: 1
  }))
}