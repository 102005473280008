
import { defineComponent } from "vue";
import StrapiService from "@/api/StrapiService";
import QuestionHeader from "@/components/QuestionHeader.vue";
import BackendService from "@/api/BackendService";

export default defineComponent({
  components: {
    QuestionHeader,
  },
  data() {
    return {
      attributes: [],
      editMode: false,
      validate: false,
      rents: [
        {
          paysRent: "initial",
          continuesRenting: "initial",
          monthlyRent: 0,
        },
      ],
    };
  },
  async created() {
    this.getLivingSituationContent();
    await this.initLivingSituation();
  },
  methods: {
    async getLivingSituationContent(): Promise<void> {
      this.editMode = this.$route.params.edit;
      const content = await StrapiService.getLivingSituationContent();
      this.attributes = content.attributes;
    },
    async setRent(value) {
      this.rents[0].paysRent = value;
      await this.$store.commit("setRents", this.rents);
    },
    async initLivingSituation() {
      try {
        const session = await BackendService.getSession();
        this.rents = session.session_data.rents;
      } catch {
        this.$router.push({ path: "error-page" });
      }
    },
    async setSessionState() {
      await this.$store.commit("setRents", this.rents);
      try {
        await BackendService.saveSession(JSON.stringify(this.$store.state));
      } catch {
        this.$router.push({ path: "error-page" });
      }
    },
    async onButtonNext() {
      if (this.rents[0].paysRent === "initial") {
        this.validate = true;
      }
      await this.setSessionState();
      if (this.rents[0].paysRent === true) {
        this.$router.push({ name: "rent", params: { edit: this.editMode } });
      }
      if (!this.rents[0].paysRent) {
        this.rents = [
          { paysRent: false, continuesRenting: "initial", monthlyRent: 0 },
        ];
        await this.setSessionState();
        if (!this.editMode) {
          this.$router.push({ name: "household" });
        } else {
          this.$router.push({ name: "summary" });
        }
      }
    },
    async onButtonBack() {
      await this.setSessionState();
      this.$router.push({ name: "person" });
    },
    cancelEdit() {
      this.$router.push({ name: "summary" });
    },
  },
});
