import BackendService from "@/api/BackendService";
import JwtService from "@/api/JwtService";
import { createStore } from "vuex";

export default createStore({
  state: {
    token: "",
    baseUrl: "",
    numberPersons: 1,
    numberChildren: 0,
    numberCars: 0,
    equity: 0,
    persons: [
      {
        firstname: "",
        lastname: "",
        salutation: "Bitte wählen",
        title: "Bitte wählen",
        birthDate: "",
        nationality: "-- Bitte wählen --",
        employment: "-- Bitte wählen --",
        address: {
          addressStreet: "",
          addressHouseNumber: "",
          addressZip: "",
          addressCity: "",
        },
      },
    ],
    rents: [
      {
        paysRent: "initial",
        continuesRenting: "initial",
        monthlyRent: 0,
      },
    ],
    multipleHouseholds: false,
    hasRealEstate: "initial",
    addRealEstate: false,
    realEstate: {
      street: "",
      houseNumber: "",
      zip: "",
      city: "",
      price: 0,
      livingSpaceInSm: 0,
      noOwnUse: false,
      netRentalIncome: 0,
      state: "-- Bitte wählen --",
      extraRealEstateCosts: 0,
      hasExtraRealEstateCosts: false,
    },
    incomes: [
      {
        income: 0,
        hasExtraIncome: false,
        listExtraIncome: [
          {
            extraSource: "-- Bitte wählen --",
            extraIncome: 0,
          },
        ],
      },
    ],
    sumExtraIncome: 0,
    expenses: [
      {
        hasInsureanceCost: "initial",
        insureanceCost: 0,
        hasMaintenanceCost: "initial",
        maintenanceCost: 0,
        hasCredits: "initial",
        listCredits: [
          {
            type: "-- Bitte wählen --",
            monthlyRate: 0,
            remainingDebt: 0,
            endDate: "",
          },
        ],
      },
    ],
    sumCredits: 0,
    fees: {
      taxes: 0,
      brokerageFee: 0,
      notaryFee: 0,
    },
    calculation: {
      maxMonthlyFinancingRate: 0,
      maxLoanAmount: 0,
      maxFinancialFramework: 0,
      purchaseCosts: 0,
      maxPurchasePrice: 0,
      taxesCosts: 0,
      brokerageFeeCosts: 0,
      notaryFeeCosts: 0,
      sumIncome: 0,
      sumCosts: 0,
      monthlyAdditionalCosts: 0,
      isCalcPurchasePrice: false,
    },
    agreedAGBs: false,
    agreedContact: false,
    wantsToBeContacted: false,
    email: "",
    phone: "",
    toggleDialogRate: false,
    requiredFieldText: "",
  },
  mutations: {
    setHasRealEstate(state, value) {
      state.hasRealEstate = value;
    },
    setBaseUrl(state, value) {
      state.baseUrl = value;
    },
    setRequiredFieldText(state, value) {
      state.requiredFieldText = value;
    },
    setAddRealEstate(state, value) {
      state.addRealEstate = value;
    },
    setEquity(state, value) {
      state.equity = value;
    },
    setFees(state, value) {
      state.fees = value;
    },
    setRents(state, value) {
      state.rents = value;
    },
    addRent(state, value) {
      state.rents.push(value);
    },
    deleteRent(state, value) {
      state.rents.splice(value, 1);
    },
    setNumberPersons(state, value) {
      state.numberPersons = value;
    },
    setNumberCars(state, value) {
      state.numberCars = value;
    },
    setNumberChildren(state, value) {
      state.numberChildren = value;
    },
    setIncomes(state, value) {
      state.incomes = value;
    },
    addIncome(state, value) {
      state.incomes.push(value);
    },
    deleteIncome(state, value) {
      state.incomes.splice(value, 1);
    },
    setSumExtraIncome(state, value) {
      state.sumExtraIncome = value;
    },
    setPersons(state, value) {
      state.persons = value;
    },
    addPerson(state, value) {
      state.persons.push(value);
    },
    deletePerson(state, value) {
      state.persons.splice(value, 1);
    },
    setRealEstate(state, value) {
      state.realEstate = value;
    },
    setExpenses(state, value) {
      state.expenses = value;
    },
    addExpense(state, value) {
      state.expenses.push(value);
    },
    deleteExpense(state, value) {
      state.expenses.splice(value, 1);
    },
    setSumCredits(state, value) {
      state.sumCredits = value;
    },
    setListCredits(state, value) {
      state.expenses[0].listCredits = value;
    },
    setHasCredits(state, value) {
      state.expenses[0].hasCredits = value;
    },
    setCalculation(state, value) {
      state.calculation = value;
    },
    setMail(state, value) {
      state.email = value;
    },
    setAgreedAGBs(state, value) {
      state.agreedAGBs = value;
    },
    setAgreedContact(state, value) {
      state.agreedContact = value;
    },
    setWantsToBeContacted(state, value) {
      state.wantsToBeContacted = value;
    },
    setPhone(state, value) {
      state.phone = value;
    },
    setToggleDialogRate(state, value) {
      state.toggleDialogRate = value;
    },
    setToken(state, value) {
      state.token = value;
    },
    setMaxMonthlyFinancingRate(state, value) {
      state.calculation.maxMonthlyFinancingRate = value;
    },
    setSumIncome(state, value) {
      state.calculation.sumIncome = value;
    },
    setSumCosts(state, value) {
      state.calculation.sumCosts = value;
    },
    setAddress(state, value) {
      state.persons[0].address = value;
    },
  },
  actions: {
    async init(context) {
      const token = JwtService.getToken();
      if (!token) {
        const token = await BackendService.init();
        JwtService.saveToken(token.access_token);
        context.commit("setToken", token.access_token);
      }
    },
  },
  modules: {},
});
