import ApiClientBackend from "./ApiClientBackend";
//import store from "@/store";

const BackendService = {
  async sendMailcertificate(data: unknown) {
    return await ApiClientBackend.post("/mailcertificate", data);
  },

  async init() {
    const token = await ApiClientBackend.get("/session-store/init");
    return token.data;
  },

  async saveSession(store) {
    return await ApiClientBackend.post("/session-store", store);
  },

  async getSession() {
    const session = await ApiClientBackend.get("/session-store");
    return session.data;
  },

  async deleteSession() {
    return await ApiClientBackend.delete("/session-store");
  },
};

export default BackendService;
