import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "col-12 col-md-8" }
const _hoisted_3 = { class: "question-content-mb-3" }
const _hoisted_4 = {
  key: 0,
  class: "button-group"
}
const _hoisted_5 = {
  key: 1,
  class: "error q-py-md"
}
const _hoisted_6 = {
  key: 2,
  class: "margin-top-3 max-width-500"
}
const _hoisted_7 = { class: "margin-top-3" }
const _hoisted_8 = {
  key: 3,
  class: "button-group margin-top-3"
}
const _hoisted_9 = {
  key: 4,
  class: "error q-py-md"
}
const _hoisted_10 = {
  key: 5,
  class: "margin-top-3 max-width-500"
}
const _hoisted_11 = {
  key: 0,
  class: "row flex justify-between margin-top-5"
}
const _hoisted_12 = { class: "row justify-start" }
const _hoisted_13 = { class: "row justify-end" }
const _hoisted_14 = {
  key: 1,
  class: "row flex justify-between margin-top-5"
}
const _hoisted_15 = { class: "row justify-start" }
const _hoisted_16 = { class: "row justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuestionHeader = _resolveComponent("QuestionHeader")!
  const _component_QCurrencyInput = _resolveComponent("QCurrencyInput")!
  const _component_q_field = _resolveComponent("q-field")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_form = _resolveComponent("q-form")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { padding: "" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_q_form, {
            ref: "form",
            greedy: "",
            onSubmit: _withModifiers(_ctx.onButtonNext, ["prevent"])
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_3, [
                  (_ctx.attributes && _ctx.attributes.questionHead)
                    ? (_openBlock(), _createBlock(_component_QuestionHeader, {
                        key: 0,
                        content: _ctx.attributes.questionHead.content,
                        headline: _ctx.attributes.questionHead.headline
                      }, null, 8, ["content", "headline"]))
                    : _createCommentVNode("", true)
                ]),
                (_ctx.attributes && _ctx.attributes.choiseButtons)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createElementVNode("div", {
                        class: _normalizeClass(["q-mr-lg button-choise", { active: _ctx.expenses[0].hasInsureanceCost === true }]),
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setHasInsureanceCost(true)))
                      }, [
                        _createElementVNode("p", null, _toDisplayString(_ctx.attributes.choiseButtons[0].buttonLeftText), 1)
                      ], 2),
                      _createElementVNode("div", {
                        class: _normalizeClass(["button-choise", { active: !_ctx.expenses[0].hasInsureanceCost }]),
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setHasInsureanceCost(false)))
                      }, [
                        _createElementVNode("p", null, _toDisplayString(_ctx.attributes.choiseButtons[0].buttonRightText), 1)
                      ], 2)
                    ]))
                  : _createCommentVNode("", true),
                (
                _ctx.expenses[0].hasInsureanceCost === 'initial' &&
                _ctx.validateInsureance
              )
                  ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.attributes.errorMessageInsureanceCost), 1))
                  : _createCommentVNode("", true),
                (_ctx.expenses[0].hasInsureanceCost === true)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createVNode(_component_q_field, {
                        filled: "",
                        modelValue: _ctx.expenses[0].insureanceCost,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.expenses[0].insureanceCost) = $event)),
                        label: _ctx.attributes.insureanceCostLabel + ' *',
                        "stack-label": "",
                        rules: [(v) => !!v || _ctx.requiredFieldText]
                      }, {
                        control: _withCtx(() => [
                          _createVNode(_component_QCurrencyInput, {
                            modelValue: _ctx.expenses[0].insureanceCost,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.expenses[0].insureanceCost) = $event)),
                            class: "q-field__input"
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      }, 8, ["modelValue", "label", "rules"])
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("p", null, _toDisplayString(_ctx.attributes.additionalQuestionLabel), 1)
                ]),
                (_ctx.attributes && _ctx.attributes.choiseButtons)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createElementVNode("div", {
                        class: _normalizeClass(["q-mr-lg button-choise", { active: _ctx.expenses[0].hasMaintenanceCost === true }]),
                        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.setHasMaintenanceCost(true)))
                      }, [
                        _createElementVNode("p", null, _toDisplayString(_ctx.attributes.choiseButtons[1].buttonLeftText), 1)
                      ], 2),
                      _createElementVNode("div", {
                        class: _normalizeClass(["button-choise", { active: !_ctx.expenses[0].hasMaintenanceCost }]),
                        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.setHasMaintenanceCost(false)))
                      }, [
                        _createElementVNode("p", null, _toDisplayString(_ctx.attributes.choiseButtons[1].buttonRightText), 1)
                      ], 2)
                    ]))
                  : _createCommentVNode("", true),
                (
                _ctx.expenses[0].hasMaintenanceCost === 'initial' &&
                _ctx.validateMaintenance
              )
                  ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(_ctx.attributes.errorMessageMaintenanceCost), 1))
                  : _createCommentVNode("", true),
                (_ctx.expenses[0].hasMaintenanceCost === true)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      _createVNode(_component_q_field, {
                        filled: "",
                        modelValue: _ctx.expenses[0].maintenanceCost,
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.expenses[0].maintenanceCost) = $event)),
                        label: _ctx.attributes.additionalQuestionInputLabel + ' *',
                        "stack-label": "",
                        rules: [(v) => !!v || _ctx.requiredFieldText]
                      }, {
                        control: _withCtx(() => [
                          _createVNode(_component_QCurrencyInput, {
                            modelValue: _ctx.expenses[0].maintenanceCost,
                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.expenses[0].maintenanceCost) = $event)),
                            class: "q-field__input"
                          }, null, 8, ["modelValue"])
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_q_icon, {
                            size: "20px",
                            name: "fas fa-info-circle",
                            class: "q-ml-sm"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_q_tooltip, { class: "bg-primary" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.attributes.tooltipMaintenanceCost), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["modelValue", "label", "rules"])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              (!_ctx.editMode)
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, [
                      (_ctx.attributes && _ctx.attributes.navigationButtons)
                        ? (_openBlock(), _createBlock(_component_q_btn, {
                            key: 0,
                            onClick: _ctx.onButtonBack,
                            outline: "",
                            color: "primary",
                            label: _ctx.attributes.navigationButtons.backButtonText
                          }, null, 8, ["onClick", "label"]))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_13, [
                      (_ctx.attributes && _ctx.attributes.navigationButtons)
                        ? (_openBlock(), _createBlock(_component_q_btn, {
                            key: 0,
                            type: "submit",
                            color: "accent",
                            label: _ctx.attributes.navigationButtons.nextButtonText
                          }, null, 8, ["label"]))
                        : _createCommentVNode("", true)
                    ])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.editMode)
                ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                    _createElementVNode("div", _hoisted_15, [
                      (_ctx.attributes && _ctx.attributes.navigationButtons)
                        ? (_openBlock(), _createBlock(_component_q_btn, {
                            key: 0,
                            type: "submit",
                            outline: "",
                            color: "primary",
                            label: _ctx.attributes.navigationButtons.CancelButtonText
                          }, null, 8, ["label"]))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_16, [
                      (_ctx.attributes && _ctx.attributes.navigationButtons)
                        ? (_openBlock(), _createBlock(_component_q_btn, {
                            key: 0,
                            type: "submit",
                            color: "accent",
                            label: _ctx.attributes.navigationButtons.SaveButtonText
                          }, null, 8, ["label"]))
                        : _createCommentVNode("", true)
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["onSubmit"])
        ])
      ])
    ]),
    _: 1
  }))
}