
import { defineComponent } from "vue";
import StrapiService from "@/api/StrapiService";
import QCurrencyInput from "@/components/QCurrencyInput.vue";
import BackendService from "@/api/BackendService";
import QCurrencyInputPercentage from "./QCurrencyInputPercentage.vue";

export default defineComponent({
  components: {
    QCurrencyInput,
    QCurrencyInputPercentage,
  },
  data() {
    return {
      keyData: [],
      yesLabel: "",
      noLabel: "",
      hasRealEstate: "initial",
      addRealEstate: "inital",
      street: "",
      houseNumber: "",
      zip: "",
      city: "",
      equity: 0,
      price: 0,
      state: "-- Bitte wählen --",
      extraRealEstateCosts: 0,
      hasExtraRealEstateCosts: false,
      livingSpaceInSm: 0,
      noOwnUse: false,
      netRentalIncome: 0,
    };
  },
  async created() {
    this.getContent();
    await this.initSummary();
  },
  methods: {
    async getContent(): Promise<void> {
      const keyDataContent = await StrapiService.getSummaryContent();
      this.keyData = keyDataContent.attributes.baseData;
      this.yesLabel = keyDataContent.attributes.yesLabel;
      this.noLabel = keyDataContent.attributes.noLabel;
    },
    async initSummary() {
      try {
        const session = await BackendService.getSession();
        const summary = session.session_data;
        this.hasRealEstate = summary.hasRealEstate;
        this.addRealEstate = summary.addRealEstate;
        this.street = summary.realEstate.street;
        this.houseNumber = summary.realEstate.houseNumber;
        this.zip = summary.realEstate.zip;
        this.city = summary.realEstate.city;
        this.equity = summary.equity;
        this.price = summary.realEstate.price;
        this.state = summary.realEstate.state;
        this.extraRealEstateCosts = summary.realEstate.extraRealEstateCosts;
        this.hasExtraRealEstateCosts =
          summary.realEstate.hasExtraRealEstateCosts;
        this.livingSpaceInSm = summary.realEstate.livingSpaceInSm;
        this.noOwnUse = summary.realEstate.noOwnUse;
        this.netRentalIncome = summary.realEstate.netRentalIncome;
      } catch {
        this.$router.push({ path: "error-page" });
      }
    },
    editEntry() {
      this.$router.push({ name: "entry", params: { edit: true } });
    },
    editKeyData() {
      this.$router.push({ name: "key-data", params: { edit: true } });
    },
  },
});
