import AddressData from "@/views/AddressData.vue";
import Costs from "@/views/Costs.vue";
import Credits from "@/views/Credits.vue";
import Entry from "@/views/Entry.vue";
import Evaluation from "@/views/Evaluation.vue";
import ErrorPage from "@/views/ErrorPage.vue";
import Final from "@/views/Final.vue";
import Household from "@/views/Household.vue";
import Income from "@/views/Income.vue";
import KeyData from "@/views/KeyData.vue";
import LivingSituation from "@/views/LivingSituation.vue";
import Modulation from "@/views/Modulation.vue";
import MonthlyRent from "@/views/MonthlyRent.vue";
import Person from "@/views/Person.vue";
import Rent from "@/views/Rent.vue";
import Success from "@/views/Success.vue";
import Summary from "@/views/Summary.vue";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/:catchAll(.*)",
    name: "error-page",
    component: ErrorPage,
    meta: {
      hideProgress: true,
    },
  },
  {
    path: "/",
    name: "entry",
    component: Entry,
    meta: {
      progress: "1",
    },
  },
  {
    path: "/key-data",
    name: "key-data",
    component: KeyData,
    meta: {
      progress: "1",
    },
  },
  {
    path: "/person",
    name: "person",
    component: Person,
    meta: {
      progress: "2",
    },
  },
  {
    path: "/living-situation",
    name: "living-situation",
    component: LivingSituation,
    meta: {
      progress: "3",
    },
  },
  {
    path: "/rent",
    name: "rent",
    component: Rent,
    meta: {
      progress: "3",
    },
  },
  {
    path: "/monthly-rent",
    name: "monthly-rent",
    component: MonthlyRent,
    meta: {
      progress: "3",
    },
  },
  {
    path: "/household",
    name: "household",
    component: Household,
    meta: {
      progress: "3",
    },
  },
  {
    path: "/income",
    name: "income",
    component: Income,
    meta: {
      progress: "4",
    },
  },
  {
    path: "/costs",
    name: "costs",
    component: Costs,
    meta: {
      progress: "5",
    },
  },
  {
    path: "/credits",
    name: "credits",
    component: Credits,
    meta: {
      progress: "5",
    },
  },
  {
    path: "/address-data",
    name: "address-data",
    component: AddressData,
    meta: {
      progress: "5",
    },
  },
  {
    path: "/summary",
    name: "summary",
    component: Summary,
    meta: {
      progress: "6",
    },
  },
  {
    path: "/evaluation",
    name: "evaluation",
    component: Evaluation,
    meta: {
      progress: "6",
    },
  },
  {
    path: "/modulation",
    name: "modulation",
    component: Modulation,
    meta: {
      hideProgress: true,
    },
  },
  {
    path: "/final",
    name: "final",
    component: Final,
    meta: {
      hideProgress: true,
    },
  },
  {
    path: "/success",
    name: "success",
    component: Success,
    meta: {
      hideProgress: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
