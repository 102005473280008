
import { defineComponent } from "vue";
import StrapiService from "@/api/StrapiService";
import QuestionHeader from "@/components/QuestionHeader.vue";
import AddCredits from "./AddCredits.vue";
import BackendService from "@/api/BackendService";

export default defineComponent({
  components: {
    QuestionHeader,
    AddCredits,
  },
  data() {
    return {
      attributes: [],
      editMode: false,
      validate: false,
      expenses: [],
      sumCredits: 0,
    };
  },
  async created() {
    this.getCreditsContent();
    await this.initCredits();
  },
  methods: {
    async getCreditsContent(): Promise<void> {
      this.editMode = this.$route.params.edit;
      const content = await StrapiService.getCreditsContent();
      this.attributes = content.attributes;
    },
    async initCredits() {
      try {
        const session = await BackendService.getSession();
        this.expenses = session.session_data.expenses;
        this.sumCredits = session.session_data.sumCredits;
      } catch {
        this.$router.push({ path: "error-page" });
      }
    },
    async setSessionState() {
      await this.$store.commit("setHasCredits", this.expenses[0].hasCredits);
      try {
        await BackendService.saveSession(JSON.stringify(this.$store.state));
      } catch {
        this.$router.push({ path: "error-page" });
      }
    },
    async onButtonNext() {
      if (!this.expenses[0]?.hasCredits) {
        await this.$store.commit("setListCredits", {
          listCredits: [
            {
              type: "",
              monthlyRate: 0,
              remainingDebt: 0,
              endDate: "",
            },
          ],
        });
        await this.$store.commit("setSumCredits", 0);
      }

      if (this.expenses[0]?.hasCredits === "initial") {
        this.validate = true;
      } else {
        await this.setSessionState();
        if (this.$refs.credits) {
          await this.$store.commit(
            "setListCredits",
            this.$refs.credits.expenses[0].listCredits
          );
          await this.$refs.credits.calcSumCredits();
          await this.setSessionState();
        }
        this.$router.push({ name: "summary" });
      }
    },
    async onButtonBack() {
      await this.setSessionState();
      this.$router.push({ name: "costs" });
    },
    setHasCredits(value) {
      this.expenses[0].hasCredits = value;
    },
  },
});
