
import { defineComponent } from "vue";
import StrapiService from "@/api/StrapiService";
import QCurrencyInput from "@/components/QCurrencyInput.vue";

export default defineComponent({
  components: {
    QCurrencyInput,
  },
  props: ["sumIncome", "sumCosts", "maxMonthlyFinancingRate", "addCosts"],
  data() {
    return {
      attributes: [],
      copyMaxMonthlyFinancingRate: 0,
      monthlyAdditionalCosts: 0,
      calcSumCost: 0,
      sumIncomeRate: 0,
      maxRate: 0,
      counter: 0,
    };
  },
  computed: {
    toggleDialogRate: {
      get() {
        return this.$store.state.toggleDialogRate;
      },
      set(value) {
        this.$store.commit("setToggleDialogRate", value);
      },
    },
  },
  created() {
    this.getEvaluation();
  },
  methods: {
    async getEvaluation(): Promise<void> {
      const content = await StrapiService.getEvaluationContent();
      this.attributes = content.attributes;
      this.monthlyAdditionalCosts = this.addCosts;
    },
    init() {
      if (this.counter === 0) {
        this.initializeCopys();
        this.calcSumCosts();
        this.sumIncomeRate = this.sumIncome;
      }
      this.counter++;
    },
    initializeCopys() {
      this.copyMaxMonthlyFinancingRate = this.maxMonthlyFinancingRate;
      this.maxRate = this.maxMonthlyFinancingRate;
    },
    calcSumCosts() {
      this.calcSumCost = this.sumCosts - 200;
    },
    calcRate() {
      this.maxRate =
        this.copyMaxMonthlyFinancingRate - this.monthlyAdditionalCosts;
    },
    updateMonthlyFinancingRate() {
      this.$store.commit("setMaxMonthlyFinancingRate", this.maxRate);
      this.$emit("updateRate", this.maxRate, this.monthlyAdditionalCosts);
    },
  },
});
