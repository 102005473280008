
import { defineComponent } from "vue";
import StrapiService from "@/api/StrapiService";
import QuestionHeader from "@/components/QuestionHeader.vue";
import SummaryCosts from "@/components/SummaryCosts.vue";
import SummaryHousehold from "@/components/SummaryHousehold.vue";
import SummaryIncome from "@/components/SummaryIncome.vue";
import SummaryKeyData from "@/components/SummaryKeyData.vue";
import SummaryPersonalData from "@/components/SummaryPersonalData.vue";

export default defineComponent({
  components: {
    QuestionHeader,
    SummaryKeyData,
    SummaryHousehold,
    SummaryIncome,
    SummaryCosts,
    SummaryPersonalData,
  },
  data() {
    return {
      attributes: [],
      editMode: false,
    };
  },
  created() {
    this.getSummaryContent();
  },
  methods: {
    async getSummaryContent(): Promise<void> {
      const content = await StrapiService.getSummaryContent();
      const attributes = content.attributes;
      this.attributes = attributes;
    },
    onButtonNext() {
      this.$router.push({ name: "evaluation" });
    },
    onButtonBack() {
      this.$router.push({ name: "credits" });
    },
  },
});
