
import { defineComponent } from "vue";
import StrapiService from "@/api/StrapiService";

export default defineComponent({
  data() {
    return {
      attributes: [],
      editMode: false,
    };
  },
  created() {
    this.getModulationContent();
  },
  methods: {
    async getModulationContent(): Promise<void> {
      const content = await StrapiService.getErrorPageContent();
      this.attributes = content.attributes;
    },
    backToStart() {
      this.$router.push({ name: "entry" });
    },
  },
});
