import ApiClient from "./ApiClient";

const StrapiService = {
  async getConfigContent() {
    const config = await ApiClient.get("/fi-be-konfiguration?populate=*");
    return config.data.data;
  },
  async getModulationContent() {
    const modulation = await ApiClient.get("/fi-be-aussteuerung?populate=*");
    return modulation.data.data;
  },

  async getEntryContent() {
    const entry = await ApiClient.get("/fi-be-einstieg?populate=*");
    return entry.data.data;
  },

  async getKeyDataContent() {
    const keyData = await ApiClient.get("/fi-be-eckdaten-mit-immo?populate=*");
    return keyData.data.data;
  },

  async getLivingSituationContent() {
    const livingSitation = await ApiClient.get(
      "/fi-be-wohnsituation?populate=*"
    );
    return livingSitation.data.data;
  },

  async getHouseholdContent() {
    const household = await ApiClient.get("/fi-be-haushalt?populate=*");
    return household.data.data;
  },

  async getIncomeContent() {
    const income = await ApiClient.get("/fi-be-einnahmen?populate=*");
    return income.data.data;
  },

  async getCostContent() {
    const cost = await ApiClient.get("/fi-be-ausgaben?populate=*");
    return cost.data.data;
  },

  async getCreditsContent() {
    const credits = await ApiClient.get("/fi-be-ausgaben-kredite?populate=*");
    return credits.data.data;
  },

  async getPersonalDataContent() {
    const personalData = await ApiClient.get(
      "/fi-be-persoenliche-daten?populate=*"
    );
    return personalData.data.data;
  },

  async getAddressDataContent() {
    const adsressData = await ApiClient.get("/fi-be-adressdaten?populate=*");
    return adsressData.data.data;
  },

  async getSummaryContent() {
    const summary = await ApiClient.get("/fi-be-zusammenfassung?populate=*");
    return summary.data.data;
  },

  async getEvaluationContent() {
    const evaluation = await ApiClient.get("/fi-be-auswertung?populate=*");
    return evaluation.data.data;
  },

  async getFinalContent() {
    const final = await ApiClient.get("/fi-be-mail-versand?populate=*");
    return final.data.data;
  },

  async getSuccessContent() {
    const success = await ApiClient.get("/fi-be-erfolg?populate=*");
    return success.data.data;
  },

  async getErrorPageContent() {
    const errorPage = await ApiClient.get("/fi-be-fehlerseite");
    return errorPage.data.data;
  },

  async getProgressContent() {
    const progress = await ApiClient.get(
      "/fi-be-fortschrittsbalken?populate=*"
    );
    return progress.data.data;
  },
};

export default StrapiService;
