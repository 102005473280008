
import StrapiService from "@/api/StrapiService";
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      progress: "",
      hasProgress: true,
      attributes: [],
    };
  },
  created() {
    this.getProgress();
  },
  watch: {
    $route: "getProgress",
  },
  mounted() {
    this.getProgress();
  },
  methods: {
    async getProgress() {
      this.progress = this.$route.meta?.progress;
      this.hasProgress = this.$route.meta?.hideProgress;
      const content = await StrapiService.getProgressContent();
      this.attributes = content.attributes;
    },
  },
});
