import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "col-12 col-md-8" }
const _hoisted_3 = { class: "question-content-mb-3" }
const _hoisted_4 = {
  key: 0,
  class: "button-group"
}
const _hoisted_5 = {
  key: 1,
  class: "error q-py-md"
}
const _hoisted_6 = { key: 2 }
const _hoisted_7 = { class: "margin-top-3" }
const _hoisted_8 = {
  key: 0,
  class: "q-mt-lg"
}
const _hoisted_9 = { class: "row q-col-gutter-sm" }
const _hoisted_10 = { class: "row q-col-gutter-sm q-pt-sm" }
const _hoisted_11 = {
  key: 0,
  class: "margin-top-5 row flex justify-end"
}
const _hoisted_12 = { class: "row" }
const _hoisted_13 = {
  key: 1,
  class: "row flex justify-between margin-top-5"
}
const _hoisted_14 = { class: "row justify-start" }
const _hoisted_15 = { class: "row justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuestionHeader = _resolveComponent("QuestionHeader")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_form = _resolveComponent("q-form")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { padding: "" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_q_form, {
            ref: "form",
            greedy: "",
            onSubmit: _withModifiers(_ctx.onButtonNext, ["prevent"])
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_3, [
                  (_ctx.attributes && _ctx.attributes.questionHead)
                    ? (_openBlock(), _createBlock(_component_QuestionHeader, {
                        key: 0,
                        content: _ctx.attributes.questionHead.content,
                        headline: _ctx.attributes.questionHead.headline
                      }, null, 8, ["content", "headline"]))
                    : _createCommentVNode("", true)
                ]),
                (_ctx.attributes && _ctx.attributes.choiseButtons)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createElementVNode("div", {
                        class: _normalizeClass(["q-mr-lg button-choise", { active: _ctx.hasRealEstate === true }]),
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setHasRealEstate(true)))
                      }, [
                        _createElementVNode("p", null, _toDisplayString(_ctx.attributes.choiseButtons.buttonLeftText), 1)
                      ], 2),
                      _createElementVNode("div", {
                        class: _normalizeClass(["button-choise", { active: !_ctx.hasRealEstate }]),
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setHasRealEstate(false)))
                      }, [
                        _createElementVNode("p", null, _toDisplayString(_ctx.attributes.choiseButtons.buttonRightText), 1)
                      ], 2)
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.hasRealEstate === 'initial' && _ctx.validate)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.attributes.errorMessage), 1))
                  : _createCommentVNode("", true),
                (_ctx.hasRealEstate === true)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("label", null, [
                          _withDirectives(_createElementVNode("input", {
                            class: "larger-checkbox",
                            type: "checkbox",
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.addRealEstate) = $event))
                          }, null, 512), [
                            [_vModelCheckbox, _ctx.addRealEstate]
                          ]),
                          _createElementVNode("span", {
                            class: "q-ml-md checkbox-span",
                            style: _normalizeStyle({ 'font-size': '1.25rem', color: '#444' })
                          }, _toDisplayString(_ctx.attributes.checkboxText), 5)
                        ])
                      ]),
                      (_ctx.addRealEstate)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                            _createElementVNode("div", _hoisted_9, [
                              _createVNode(_component_q_input, {
                                class: "col-12 col-md-8",
                                filled: "",
                                modelValue: _ctx.realEstate.street,
                                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.realEstate.street) = $event)),
                                label: _ctx.attributes.streetLabel + ' *',
                                "stack-label": "",
                                rules: [(v) => !!v || _ctx.requiredFieldText]
                              }, null, 8, ["modelValue", "label", "rules"]),
                              _createVNode(_component_q_input, {
                                class: "col-12 col-md-4",
                                filled: "",
                                modelValue: _ctx.realEstate.houseNumber,
                                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.realEstate.houseNumber) = $event)),
                                label: _ctx.attributes.numberLabel + ' *',
                                "stack-label": "",
                                rules: [(v) => !!v || _ctx.requiredFieldText]
                              }, null, 8, ["modelValue", "label", "rules"])
                            ]),
                            _createElementVNode("div", _hoisted_10, [
                              _createVNode(_component_q_input, {
                                class: "col-12 col-md-4",
                                filled: "",
                                modelValue: _ctx.realEstate.zip,
                                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.realEstate.zip) = $event)),
                                label: _ctx.attributes.zipLabel + ' *',
                                "stack-label": "",
                                rules: [(v) => !!v || _ctx.requiredFieldText]
                              }, null, 8, ["modelValue", "label", "rules"]),
                              _createVNode(_component_q_input, {
                                class: "col-12 col-md-8 q-pl",
                                filled: "",
                                modelValue: _ctx.realEstate.city,
                                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.realEstate.city) = $event)),
                                label: _ctx.attributes.cityLabel + ' *',
                                "stack-label": "",
                                rules: [(v) => !!v || _ctx.requiredFieldText]
                              }, null, 8, ["modelValue", "label", "rules"])
                            ])
                          ]))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true)
              ]),
              (!_ctx.editMode)
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, [
                      (_ctx.attributes && _ctx.attributes.navigationButtons)
                        ? (_openBlock(), _createBlock(_component_q_btn, {
                            key: 0,
                            color: "accent",
                            label: _ctx.attributes.navigationButtons.nextButtonText,
                            type: "submit"
                          }, null, 8, ["label"]))
                        : _createCommentVNode("", true)
                    ])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.editMode)
                ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                    _createElementVNode("div", _hoisted_14, [
                      (_ctx.attributes && _ctx.attributes.navigationButtons)
                        ? (_openBlock(), _createBlock(_component_q_btn, {
                            key: 0,
                            type: "submit",
                            outline: "",
                            color: "primary",
                            label: _ctx.attributes.navigationButtons.CancelButtonText
                          }, null, 8, ["label"]))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_15, [
                      (_ctx.attributes && _ctx.attributes.navigationButtons)
                        ? (_openBlock(), _createBlock(_component_q_btn, {
                            key: 0,
                            type: "submit",
                            color: "accent",
                            label: _ctx.attributes.navigationButtons.SaveButtonText
                          }, null, 8, ["label"]))
                        : _createCommentVNode("", true)
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["onSubmit"])
        ])
      ])
    ]),
    _: 1
  }))
}