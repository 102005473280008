
import { defineComponent } from "vue";
import StrapiService from "@/api/StrapiService";
import BackendService from "@/api/BackendService";

export default defineComponent({
  data() {
    return {
      attributes: [],
      editMode: false,
      address: {
        addressCity: "",
        addressHouseNumber: "",
        addressStreet: "",
        addressZip: "",
      },
      requiredFieldText: "",
    };
  },
  async created() {
    this.getAddressDataContent();
    await this.initAddress();
  },
  methods: {
    async getAddressDataContent(): Promise<void> {
      this.editMode = this.$route.params.edit;
      const content = await StrapiService.getAddressDataContent();
      this.attributes = content.attributes;
    },
    async initAddress() {
      try {
        const session = await BackendService.getSession();
        const data = session.session_data;
        this.address = data.persons[0].address;
        this.requiredFieldText = data.requiredFieldText;
      } catch {
        this.$router.push({ path: "error-page" });
      }
    },
  },
});
