
import { defineComponent } from "vue";
import StrapiService from "@/api/StrapiService";
import QCurrencyInput from "@/components/QCurrencyInput.vue";
import BackendService from "@/api/BackendService";

export default defineComponent({
  components: {
    QCurrencyInput,
  },
  data() {
    return {
      incomeLabels: [],
      employment: "",
      income: 0,
      hasExtraIncome: false,
      listExtraIncome: [],
      yesLabel: "",
      noLabel: "",
    };
  },
  async created() {
    this.getContent();
    await this.initSummary();
  },
  methods: {
    async getContent(): Promise<void> {
      const incomeContent = await StrapiService.getSummaryContent();
      this.incomeLabels = incomeContent.attributes.income;
      this.yesLabel = incomeContent.attributes.yesLabel;
      this.noLabel = incomeContent.attributes.noLabel;
    },
    async initSummary() {
      try {
        const session = await BackendService.getSession();
        const summary = session.session_data;
        this.employment = summary.persons[0].employment;
        this.income = summary.incomes[0].income;
        this.hasExtraIncome = summary.incomes[0].hasExtraIncome;
        this.listExtraIncome = summary.incomes[0].listExtraIncome;
      } catch {
        this.$router.push({ path: "error-page" });
      }
    },
    editIncome() {
      this.$router.push({ name: "income", params: { edit: true } });
    },
  },
});
