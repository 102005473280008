import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "col-12 col-md-8" }
const _hoisted_2 = { class: "margin-top-3" }
const _hoisted_3 = { class: "row q-col-gutter-sm" }
const _hoisted_4 = { class: "row q-col-gutter-sm q-mt-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_input = _resolveComponent("q-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_q_input, {
            class: "col-12 col-md-8",
            filled: "",
            modelValue: _ctx.address.addressStreet,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.address.addressStreet) = $event)),
            label: _ctx.attributes.streetLabel + ' *',
            "stack-label": "",
            rules: [(v) => !!v || _ctx.requiredFieldText]
          }, null, 8, ["modelValue", "label", "rules"]),
          _createVNode(_component_q_input, {
            class: "col-12 col-md-4",
            filled: "",
            modelValue: _ctx.address.addressHouseNumber,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.address.addressHouseNumber) = $event)),
            label: _ctx.attributes.numberLabel + ' *',
            "stack-label": "",
            rules: [(v) => !!v || _ctx.requiredFieldText]
          }, null, 8, ["modelValue", "label", "rules"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_q_input, {
            class: "col-12 col-md-4",
            filled: "",
            modelValue: _ctx.address.addressZip,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.address.addressZip) = $event)),
            label: _ctx.attributes.zipLabel + ' *',
            "stack-label": "",
            rules: [(v) => !!v || _ctx.requiredFieldText]
          }, null, 8, ["modelValue", "label", "rules"]),
          _createVNode(_component_q_input, {
            class: "col-12 col-md-8",
            filled: "",
            modelValue: _ctx.address.addressCity,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.address.addressCity) = $event)),
            label: _ctx.attributes.cityLabel + ' *',
            "stack-label": "",
            rules: [(v) => !!v || _ctx.requiredFieldText]
          }, null, 8, ["modelValue", "label", "rules"])
        ])
      ])
    ])
  ]))
}