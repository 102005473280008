
import { defineComponent } from "vue";
import StrapiService from "@/api/StrapiService";
import QuestionHeader from "@/components/QuestionHeader.vue";
import BackendService from "@/api/BackendService";
import AddressData from "./AddressData.vue";

export default defineComponent({
  components: {
    QuestionHeader,
    AddressData,
  },
  data() {
    return {
      attributes: [],
      validateLegal: false,
      email: "",
      phone: "",
      agreedAGBs: false,
      agreedContact: false,
      wantsToBeContacted: false,
      requiredFieldText: "",
    };
  },
  async created() {
    this.getFinalContent();
    await this.initFinal();
  },
  methods: {
    async getFinalContent(): Promise<void> {
      const content = await StrapiService.getFinalContent();
      this.attributes = content.attributes;
    },
    async initFinal() {
      try {
        const session = await BackendService.getSession();
        const data = session.session_data;
        this.email = data.email;
        this.phone = data.phone;
        this.agreedAGBs = data.agreedAGBs;
        this.agreedContact = data.agreedContact;
        this.wantsToBeContacted = data.wantsToBeContacted;
        this.requiredFieldText = data.requiredFieldText;
      } catch {
        this.$router.push({ path: "error-page" });
      }
    },
    async setSessionState() {
      await this.$store.commit("setAddress", this.$refs.address.address);
      await this.$store.commit("setMail", this.email);
      await this.$store.commit("setPhone", this.phone);
      await this.$store.commit("setAgreedAGBs", this.agreedAGBs);
      await this.$store.commit("setAgreedContact", this.agreedContact);
      await this.$store.commit(
        "setWantsToBeContacted",
        this.wantsToBeContacted
      );
      try {
        await BackendService.saveSession(JSON.stringify(this.$store.state));
      } catch {
        this.$router.push({ path: "error-page" });
      }
    },
    async onButtonNext() {
      if (!this.agreedAGBs) {
        this.validateLegal = true;
      } else {
        try {
          await this.setSessionState();
          await BackendService.sendMailcertificate(
            JSON.stringify(this.$store.state)
          );
          this.$router.push({ name: "success" });
        } catch {
          this.$router.push({ path: "*" });
        }
      }
    },
  },
});
