
import { defineComponent } from "vue";
import StrapiService from "@/api/StrapiService";
import QuestionHeader from "@/components/QuestionHeader.vue";
import openURL from 'quasar/src/utils/open-url.js';;

export default defineComponent({
  components: {
    QuestionHeader,
  },
  data() {
    return {
      attributes: [],
      editMode: false,
    };
  },
  created() {
    this.getModulationContent();
  },
  methods: {
    async getModulationContent(): Promise<void> {
      const content = await StrapiService.getModulationContent();
      const attributes = content.attributes;
      this.attributes = attributes;
    },
    tvo() {
      openURL(this.attributes.tvo);
    },
  },
});
