import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.headline)
      ? (_openBlock(), _createElementBlock("h1", _hoisted_1, _toDisplayString(_ctx.headline), 1))
      : _createCommentVNode("", true),
    (_ctx.contentMarkdown)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "question-content",
          innerHTML: _ctx.contentMarkdown
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true)
  ], 64))
}