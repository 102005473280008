
import { defineComponent } from "vue";
import StrapiService from "@/api/StrapiService";
import QuestionHeader from "@/components/QuestionHeader.vue";
import QCurrencyInput from "@/components/QCurrencyInput.vue";
import BackendService from "@/api/BackendService";
import QCurrencyInputPercentage from "@/components/QCurrencyInputPercentage.vue";

export default defineComponent({
  components: {
    QuestionHeader,
    QCurrencyInput,
    QCurrencyInputPercentage,
  },
  data() {
    return {
      attributes: [],
      stateOptions: [],
      editMode: false,
      equity: 0,
      hasRealEstate: false,
      realEstate: {
        state: "-- Bitte wählen --",
        price: 0,
        livingSpaceInSm: 0,
        noOwnUse: false,
        netRentalIncome: 0,
        extraRealEstateCosts: 0,
        hasExtraRealEstateCosts: false,
      },
      requiredFieldText: "",
      minRealEstatePrice: 0,
    };
  },
  async created() {
    this.getKeyDataContent();
    await this.initKeyData();
  },
  methods: {
    async getKeyDataContent(): Promise<void> {
      this.editMode = this.$route.params.edit;
      const content = await StrapiService.getKeyDataContent();
      this.attributes = content.attributes;

      const config = await StrapiService.getConfigContent();
      this.minRealEstatePrice = config.attributes.minRealEstatePrice;

      let stateOptions = [];
      this.attributes.states.data.forEach((element) => {
        stateOptions.push(element.attributes.name);
        this.stateOptions = stateOptions;
      });
    },
    async initKeyData() {
      try {
        const session = await BackendService.getSession();
        this.equity = session.session_data.equity;
        this.hasRealEstate = session.session_data.hasRealEstate;
        this.realEstate = session.session_data.realEstate;
        this.requiredFieldText = session.session_data.requiredFieldText;
      } catch {
        this.$router.push({ path: "error-page" });
      }
    },
    async setAdditionalPurchaseCostVariables() {
      this.attributes.states.data.forEach(async (element) => {
        if (element.attributes.name === this.realEstate.state) {
          await this.$store.commit("setFees", {
            taxes: element.attributes.taxes,
            brokerageFee: element.attributes.brokerageFee,
            notaryFee: element.attributes.notaryFee,
          });
        }
      });
    },
    async setSessionState() {
      await this.$store.commit("setEquity", this.equity);
      await this.$store.commit("setRealEstate", this.realEstate);
      try {
        await BackendService.saveSession(JSON.stringify(this.$store.state));
      } catch {
        this.$router.push({ path: "error-page" });
      }
    },
    async onButtonNext() {
      await this.setAdditionalPurchaseCostVariables();
      if (!this.realEstate.hasExtraRealEstateCosts) {
        this.realEstate.extraRealEstateCosts = 0;
      }
      if (!this.realEstate.noOwnUse) {
        this.realEstate.netRentalIncome = 0;
      }
      await this.setSessionState();
      if (this.realEstate.price < this.minRealEstatePrice) {
        this.$router.push({ name: "modulation" });
      }
      if (!this.editMode && this.realEstate.price >= this.minRealEstatePrice) {
        this.$router.push({ name: "person" });
      }
      if (this.editMode && this.realEstate.price >= this.minRealEstatePrice) {
        this.$router.push({ name: "summary" });
      }
    },
    async onButtonBack() {
      await this.setAdditionalPurchaseCostVariables();
      await this.setSessionState();
      this.$router.push({ name: "entry" });
    },
  },
});
