import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "col-12 col-md-8" }
const _hoisted_3 = { class: "question-content-mb-3" }
const _hoisted_4 = { class: "margin-top-3" }
const _hoisted_5 = ["href"]
const _hoisted_6 = ["href"]
const _hoisted_7 = { class: "margin-top-5" }
const _hoisted_8 = { class: "row justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuestionHeader = _resolveComponent("QuestionHeader")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { padding: "" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_3, [
              (_ctx.attributes && _ctx.attributes.questionHead)
                ? (_openBlock(), _createBlock(_component_QuestionHeader, {
                    key: 0,
                    content: _ctx.attributes.questionHead.content,
                    headline: _ctx.attributes.questionHead.headline
                  }, null, 8, ["content", "headline"]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("p", null, [
                _createVNode(_component_q_icon, {
                  color: "primary",
                  class: "fas fa-envelope q-mr-sm"
                }),
                _createElementVNode("a", {
                  href: `mailto:${_ctx.attributes.email}`
                }, _toDisplayString(_ctx.attributes.email), 9, _hoisted_5)
              ]),
              _createElementVNode("p", null, [
                _createVNode(_component_q_icon, {
                  color: "primary",
                  class: "fas fa-phone q-mr-sm"
                }),
                _createElementVNode("a", {
                  href: `tel:${_ctx.attributes.phone}`
                }, _toDisplayString(_ctx.attributes.phone), 9, _hoisted_6)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_q_btn, {
                onClick: _ctx.tvo,
                color: "accent",
                label: _ctx.attributes.contactButtonLabel
              }, null, 8, ["onClick", "label"])
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}