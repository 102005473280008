
import { defineComponent } from "vue";
import MarkdownIt from "markdown-it";
let md = new MarkdownIt();

export default defineComponent({
  name: "question-header",
  props: {
    headline: String,
    content: String,
  },
  data() {
    return {
      contentMarkdown: this.content,
    };
  },
  created() {
    this.useMarkdown();
  },
  methods: {
    useMarkdown() {
      return (this.contentMarkdown = md.render(this.contentMarkdown));
    },
  },
});
