
import { defineComponent } from "vue";
import StrapiService from "@/api/StrapiService";
import QuestionHeader from "@/components/QuestionHeader.vue";
import BackendService from "@/api/BackendService";

export default defineComponent({
  components: {
    QuestionHeader,
  },
  data() {
    return {
      attributes: [],
      editMode: false,
      validate: false,
      numberPersons: 1,
      numberChildren: 0,
      numberCars: 0,
      rents: [],
    };
  },
  async created() {
    this.getHouseholdContent();
    await this.initHousehold();
  },
  methods: {
    async getHouseholdContent(): Promise<void> {
      this.editMode = this.$route.params.edit;
      const content = await StrapiService.getHouseholdContent();
      this.attributes = content.attributes;
    },
    async initHousehold() {
      try {
        const session = await BackendService.getSession();
        this.numberPersons = session.session_data.numberPersons;
        this.numberChildren = session.session_data.numberChildren;
        this.numberCars = session.session_data.numberCars;
        this.rents = session.session_data.rents;
      } catch {
        this.$router.push({ path: "error-page" });
      }
    },
    async setSessionState() {
      await this.$store.commit("setNumberPersons", this.numberPersons);
      await this.$store.commit("setNumberChildren", this.numberChildren);
      await this.$store.commit("setNumberCars", this.numberCars);
      try {
        await BackendService.saveSession(JSON.stringify(this.$store.state));
      } catch {
        this.$router.push({ path: "error-page" });
      }
    },
    async onButtonNext() {
      await this.setSessionState();
      if (this.numberPersons - this.numberChildren < 1) {
        this.validate = true;
      } else if (!this.editMode) {
        this.$router.push({ name: "income" });
      } else {
        this.$router.push({ name: "summary" });
      }
    },
    async onButtonBack() {
      await this.setSessionState();
      if (this.rents[0].monthlyRent !== 0) {
        this.$router.push({ name: "monthly-rent" });
      } else if (this.rents[0].continuesRenting !== "initial") {
        this.$router.push({ name: "rent" });
      } else {
        this.$router.push({ name: "living-situation" });
      }
    },
  },
});
