
import { defineComponent } from "vue";
import StrapiService from "@/api/StrapiService";
import QuestionHeader from "@/components/QuestionHeader.vue";
import BackendService from "@/api/BackendService";

export default defineComponent({
  components: {
    QuestionHeader,
  },
  data() {
    return {
      attributes: [],
      email: "",
    };
  },
  async created() {
    this.getSuccessContent();
    await this.initSuccess();
  },
  methods: {
    async getSuccessContent(): Promise<void> {
      const content = await StrapiService.getSuccessContent();
      this.attributes = content.attributes;
    },
    async initSuccess() {
      try {
        const session = await BackendService.getSession();
        this.email = session.session_data.email;
      } catch {
        this.$router.push({ path: "error-page" });
      }
    },
  },
});
