
import { defineComponent } from "vue";
import StrapiService from "@/api/StrapiService";
import QuestionHeader from "@/components/QuestionHeader.vue";
import BackendService from "@/api/BackendService";

export default defineComponent({
  components: {
    QuestionHeader,
  },
  data() {
    return {
      attributes: [],
      config: [],
      persons: [
        {
          birthDate: "",
          employment: "",
          firstname: "",
          lastname: "",
          nationality: "-- Bitte wählen --",
          salutation: "Bitte wählen",
          title: "Bitte wählen",
        },
      ],
      salutationOptions: [],
      titleOptions: [],
      nationalityOptions: [],
      editMode: false,
      requiredFieldText: "",
      myLocale: {
        days: [
          "Montag",
          "Dienstag",
          "Mittwoch",
          "Donnerstag",
          "Freitag",
          "Samstag",
          "Sonntag",
        ],
        daysShort: ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"],
        months: [
          "Januar",
          "Februar",
          "März",
          "April",
          "Mai",
          "Juni",
          "Juli",
          "August",
          "September",
          "Oktober",
          "November",
          "Dezember",
        ],
        monthsShort: [
          "Jan.",
          "Feb.",
          "März",
          "Apr.",
          "Mai",
          "Jun.",
          "Jul.",
          "Aug.",
          "Sept.",
          "Okt.",
          "Nov.",
          "Dez.",
        ],
        firstDayOfWeek: 0,
      },
    };
  },
  async created() {
    this.getPersonContent();
    await this.initPersons();
  },
  methods: {
    async getPersonContent(): Promise<void> {
      this.editMode = this.$route.params.edit;
      const content = await StrapiService.getPersonalDataContent();
      this.attributes = content.attributes;

      const config = await StrapiService.getConfigContent();
      this.config = config.attributes;

      let salutationOptions = [];
      this.attributes.salutation.data.forEach((element) => {
        salutationOptions.push(element.attributes.anrede);
        this.salutationOptions = salutationOptions;
      });

      let nationalityOptions = [];
      this.attributes.nationality.data.forEach((element) => {
        nationalityOptions.push(element.attributes.nationality);
        this.nationalityOptions = nationalityOptions;
      });

      let titleOptions = [];
      this.attributes.title.data.forEach((element) => {
        titleOptions.push(element.attributes.title);
        this.titleOptions = titleOptions;
      });
    },
    async initPersons() {
      try {
        const session = await BackendService.getSession();
        this.persons = session.session_data.persons;
        this.requiredFieldText = session.session_data.requiredFieldText;
      } catch {
        this.$router.push({ path: "error-page" });
      }
    },
    async setPerson() {
      await this.$store.commit("setPersons", this.persons);
    },
    async setSessionState() {
      try {
        await BackendService.saveSession(JSON.stringify(this.$store.state));
      } catch {
        this.$router.push({ path: "error-page" });
      }
    },
    async onButtonNext() {
      const thisYear = new Date().getFullYear();
      const givenYear = this.persons[0].birthDate.substr(
        this.persons[0].birthDate.length - 4
      );
      const age = thisYear - givenYear;
      const maxAge = this.config.maxAge;
      const minAge = this.config.minAge;

      await this.setPerson();
      await this.setSessionState();
      if (age >= maxAge || age < minAge) {
        this.$router.push({ name: "modulation" });
      }
      if (!this.editMode && age < maxAge && age >= minAge) {
        this.$router.push({ name: "living-situation" });
      }
      if (this.editMode && age < maxAge && age >= minAge) {
        this.$router.push({ name: "summary" });
      }
    },
    async onButtonBack() {
      await this.setPerson();
      await this.setSessionState();
      this.$router.push({ name: "key-data" });
    },
  },
});
