import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "col-12 col-md-8" }
const _hoisted_3 = { class: "question-content-mb-3" }
const _hoisted_4 = { class: "margin-top-3" }
const _hoisted_5 = { class: "q-py-md" }
const _hoisted_6 = { class: "row flex justify-between margin-top-5" }
const _hoisted_7 = { class: "row justify-start" }
const _hoisted_8 = { class: "row justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuestionHeader = _resolveComponent("QuestionHeader")!
  const _component_SummaryKeyData = _resolveComponent("SummaryKeyData")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_expansion_item = _resolveComponent("q-expansion-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_SummaryPersonalData = _resolveComponent("SummaryPersonalData")!
  const _component_SummaryHousehold = _resolveComponent("SummaryHousehold")!
  const _component_SummaryIncome = _resolveComponent("SummaryIncome")!
  const _component_SummaryCosts = _resolveComponent("SummaryCosts")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { padding: "" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_3, [
              (_ctx.attributes && _ctx.attributes.questionHead)
                ? (_openBlock(), _createBlock(_component_QuestionHeader, {
                    key: 0,
                    content: _ctx.attributes.questionHead.content,
                    headline: _ctx.attributes.questionHead.headline
                  }, null, 8, ["content", "headline"]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_q_list, { bordered: "" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_expansion_item, {
                      class: "large-label",
                      group: "somegroup",
                      label: _ctx.attributes.headerTitle?.headerTitle1,
                      "default-opened": "",
                      "header-class": "bg-grey-2 grey-8"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_card, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_card_section, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_SummaryKeyData)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_q_list, {
                  class: "q-mt-md",
                  bordered: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_expansion_item, {
                      class: "large-label",
                      group: "somegroup",
                      label: _ctx.attributes.headerTitle?.headerTitle2,
                      "header-class": "bg-grey-2 grey-8"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_card, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_card_section, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_SummaryPersonalData)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_q_list, {
                  class: "q-mt-md",
                  bordered: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_expansion_item, {
                      class: "large-label",
                      group: "somegroup",
                      label: _ctx.attributes.headerTitle?.headerTitle3,
                      "header-class": "bg-grey-2 grey-8"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_card, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_card_section, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_SummaryHousehold)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_q_list, {
                  class: "q-mt-md",
                  bordered: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_expansion_item, {
                      class: "large-label",
                      group: "somegroup",
                      label: _ctx.attributes.headerTitle?.headerTitle4,
                      "header-class": "bg-grey-2 grey-8"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_card, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_card_section, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_SummaryIncome)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_q_list, {
                  class: "q-mt-md",
                  bordered: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_expansion_item, {
                      class: "large-label",
                      group: "somegroup",
                      label: _ctx.attributes.headerTitle?.headerTitle5,
                      "header-class": "bg-grey-2 grey-8"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_card, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_card_section, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_SummaryCosts)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _: 1
                })
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              (_ctx.attributes && _ctx.attributes.navigationButtons)
                ? (_openBlock(), _createBlock(_component_q_btn, {
                    key: 0,
                    onClick: _ctx.onButtonBack,
                    outline: "",
                    color: "primary",
                    label: 
                _ctx.$q.screen.gt.sm
                  ? _ctx.attributes.navigationButtons.backButtonText
                  : '<'
              
                  }, null, 8, ["onClick", "label"]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_8, [
              (_ctx.attributes && _ctx.attributes.navigationButtons)
                ? (_openBlock(), _createBlock(_component_q_btn, {
                    key: 0,
                    onClick: _ctx.onButtonNext,
                    color: "accent",
                    label: _ctx.attributes.navigationButtons.nextButtonText
                  }, null, 8, ["onClick", "label"]))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}