import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "col-12 col-md-8 margin-top-3" }
const _hoisted_2 = { class: "question-content-mb-3" }
const _hoisted_3 = { class: "margin-top-3" }
const _hoisted_4 = { class: "row items-center justify-end" }
const _hoisted_5 = { class: "row justify-end" }
const _hoisted_6 = { class: "row justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuestionHeader = _resolveComponent("QuestionHeader")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_QCurrencyInput = _resolveComponent("QCurrencyInput")!
  const _component_q_field = _resolveComponent("q-field")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_date = _resolveComponent("q-date")!
  const _component_q_popup_proxy = _resolveComponent("q-popup-proxy")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.attributes && _ctx.attributes.questionHead)
          ? (_openBlock(), _createBlock(_component_QuestionHeader, {
              key: 0,
              content: _ctx.attributes.questionHead[1].content
            }, null, 8, ["content"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.expenses[0]?.listCredits, (credit, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "row q-col-gutter-sm q-pt-md",
            key: index
          }, [
            (_ctx.creditTypeOptions)
              ? (_openBlock(), _createBlock(_component_q_select, {
                  key: 0,
                  class: "col-12 col-md-3",
                  filled: "",
                  modelValue: credit.type,
                  "onUpdate:modelValue": ($event: any) => ((credit.type) = $event),
                  options: _ctx.creditTypeOptions,
                  label: _ctx.attributes.creditTypeLabel + ' *',
                  "stack-label": "",
                  rules: [
              (creditTypeOptions) =>
                creditTypeOptions !== '-- Bitte wählen --' || _ctx.requiredFieldText,
            ]
                }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "label", "rules"]))
              : _createCommentVNode("", true),
            _createVNode(_component_q_field, {
              class: "col-12 col-md-2",
              filled: "",
              modelValue: credit.monthlyRate,
              "onUpdate:modelValue": ($event: any) => ((credit.monthlyRate) = $event),
              label: _ctx.attributes.monthlyRateLabel + ' *',
              "stack-label": "",
              rules: [(v) => !!v || _ctx.requiredFieldText]
            }, {
              control: _withCtx(() => [
                _createVNode(_component_QCurrencyInput, {
                  modelValue: credit.monthlyRate,
                  "onUpdate:modelValue": ($event: any) => ((credit.monthlyRate) = $event),
                  class: "q-field__input"
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]),
              _: 2
            }, 1032, ["modelValue", "onUpdate:modelValue", "label", "rules"]),
            _createVNode(_component_q_field, {
              class: "col-12 col-md-3",
              filled: "",
              modelValue: credit.remainingDebt,
              "onUpdate:modelValue": ($event: any) => ((credit.remainingDebt) = $event),
              label: _ctx.attributes.remainingDebtLabel,
              "stack-label": ""
            }, {
              control: _withCtx(() => [
                _createVNode(_component_QCurrencyInput, {
                  modelValue: credit.remainingDebt,
                  "onUpdate:modelValue": ($event: any) => ((credit.remainingDebt) = $event),
                  class: "q-field__input"
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]),
              _: 2
            }, 1032, ["modelValue", "onUpdate:modelValue", "label"]),
            _createVNode(_component_q_input, {
              class: "col-12 col-md-3",
              filled: "",
              "stack-label": "",
              "bottom-slots": "",
              modelValue: credit.endDate,
              "onUpdate:modelValue": ($event: any) => ((credit.endDate) = $event),
              placeholder: "'TT.MM.JJJJ'",
              mask: "##.##.####",
              label: _ctx.attributes.endDateLabel
            }, {
              append: _withCtx(() => [
                _createVNode(_component_q_icon, {
                  name: "far fa-calendar-alt",
                  class: "text-primary cursor-pointer"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_popup_proxy, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_date, {
                          modelValue: credit.endDate,
                          "onUpdate:modelValue": ($event: any) => ((credit.endDate) = $event),
                          minimal: "",
                          mask: "DD.MM.YYYY",
                          "default-view": "Years",
                          locale: _ctx.myLocale
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_4, [
                              _withDirectives(_createVNode(_component_q_btn, {
                                label: "OK",
                                color: "primary",
                                flat: ""
                              }, null, 512), [
                                [_directive_close_popup]
                              ])
                            ])
                          ]),
                          _: 2
                        }, 1032, ["modelValue", "onUpdate:modelValue", "locale"])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1032, ["modelValue", "onUpdate:modelValue", "label"]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_q_btn, {
                class: "col-12 col-md-1 q-ml-md",
                round: "",
                style: { height: '56px', width: '56px' },
                color: "accent",
                icon: "fas fa-minus",
                onClick: ($event: any) => (_ctx.deleteRow(index))
              }, null, 8, ["onClick"])
            ])
          ]))
        }), 128)),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_q_btn, {
            class: "q-mr-xs",
            round: "",
            style: { height: '56px', width: '56px' },
            color: "primary",
            icon: "fas fa-plus",
            onClick: _ctx.addRow
          }, null, 8, ["onClick"])
        ])
      ])
    ])
  ]))
}