import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "col-12 col-md-8" }
const _hoisted_3 = { class: "question-content-mb-3" }
const _hoisted_4 = { class: "q-gutter-md margin-top-3 max-width-500" }
const _hoisted_5 = { class: "q-mt-md" }
const _hoisted_6 = {
  key: 0,
  class: "q-gutter-md q-mt-md max-width-500"
}
const _hoisted_7 = { class: "q-mt-md" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = {
  key: 1,
  class: "q-gutter-md q-mt-md max-width-500"
}
const _hoisted_10 = {
  key: 2,
  class: "row flex justify-between margin-top-5"
}
const _hoisted_11 = { class: "row justify-start" }
const _hoisted_12 = { class: "row justify-end" }
const _hoisted_13 = {
  key: 3,
  class: "row flex justify-between margin-top-5"
}
const _hoisted_14 = { class: "row justify-start" }
const _hoisted_15 = { class: "row justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuestionHeader = _resolveComponent("QuestionHeader")!
  const _component_QCurrencyInput = _resolveComponent("QCurrencyInput")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_field = _resolveComponent("q-field")!
  const _component_QCurrencyInputPercentage = _resolveComponent("QCurrencyInputPercentage")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_form = _resolveComponent("q-form")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { padding: "" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_3, [
              (_ctx.attributes && _ctx.attributes.questionHead && _ctx.hasRealEstate)
                ? (_openBlock(), _createBlock(_component_QuestionHeader, {
                    key: 0,
                    content: _ctx.attributes.questionHead[0].content,
                    headline: _ctx.attributes.questionHead[0].headline
                  }, null, 8, ["content", "headline"]))
                : _createCommentVNode("", true),
              (_ctx.attributes && _ctx.attributes.questionHead && !_ctx.hasRealEstate)
                ? (_openBlock(), _createBlock(_component_QuestionHeader, {
                    key: 1,
                    content: _ctx.attributes.questionHead[1].content,
                    headline: _ctx.attributes.questionHead[1].headline
                  }, null, 8, ["content", "headline"]))
                : _createCommentVNode("", true)
            ]),
            _createVNode(_component_q_form, {
              ref: "form",
              greedy: "",
              onSubmit: _withModifiers(_ctx.onButtonNext, ["prevent"])
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_q_field, {
                    class: "col-8 col-md-12",
                    filled: "",
                    modelValue: _ctx.equity,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.equity) = $event)),
                    label: _ctx.attributes.equityLabel + ' *',
                    "stack-label": "",
                    rules: [(v) => !!v || _ctx.requiredFieldText]
                  }, {
                    control: _withCtx(() => [
                      _createVNode(_component_QCurrencyInput, {
                        modelValue: _ctx.equity,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.equity) = $event)),
                        class: "q-field__input"
                      }, null, 8, ["modelValue"])
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_q_icon, {
                        size: "20px",
                        name: "fas fa-info-circle",
                        class: "q-ml-sm"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_tooltip, { class: "bg-primary" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.attributes.tooltipEquity), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue", "label", "rules"]),
                  _createVNode(_component_q_field, {
                    class: "col-8 col-md-12",
                    filled: "",
                    modelValue: _ctx.realEstate.price,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.realEstate.price) = $event)),
                    label: 
                  _ctx.hasRealEstate
                    ? _ctx.attributes.priceLabel + ' *'
                    : _ctx.attributes.expectedCostLabel + ' *'
                ,
                    "stack-label": "",
                    rules: [(v) => !!v || _ctx.requiredFieldText]
                  }, {
                    control: _withCtx(() => [
                      _createVNode(_component_QCurrencyInput, {
                        modelValue: _ctx.realEstate.price,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.realEstate.price) = $event)),
                        class: "q-field__input"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }, 8, ["modelValue", "label", "rules"]),
                  _createVNode(_component_q_field, {
                    class: "col-8 col-md-12",
                    filled: "",
                    modelValue: _ctx.realEstate.livingSpaceInSm,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.realEstate.livingSpaceInSm) = $event)),
                    label: _ctx.attributes.livingSpaceInSmLabel,
                    "stack-label": "",
                    rules: [(v) => !!v || _ctx.requiredFieldText]
                  }, {
                    control: _withCtx(() => [
                      _createVNode(_component_QCurrencyInputPercentage, {
                        modelValue: _ctx.realEstate.livingSpaceInSm,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.realEstate.livingSpaceInSm) = $event)),
                        class: "q-field__input"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }, 8, ["modelValue", "label", "rules"]),
                  _createVNode(_component_q_select, {
                    filled: "",
                    modelValue: _ctx.realEstate.state,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.realEstate.state) = $event)),
                    options: _ctx.stateOptions,
                    label: _ctx.attributes.stateLabel + ' *',
                    "stack-label": "",
                    rules: [
                  (stateOptions) =>
                    stateOptions !== '-- Bitte wählen --' || _ctx.requiredFieldText,
                ]
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_icon, {
                        size: "20px",
                        name: "fas fa-info-circle",
                        class: "q-ml-sm"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_tooltip, { class: "bg-primary" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.attributes.tooltipState), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue", "options", "label", "rules"])
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("label", null, [
                    _withDirectives(_createElementVNode("input", {
                      class: "larger-checkbox",
                      type: "checkbox",
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.realEstate.noOwnUse) = $event))
                    }, null, 512), [
                      [_vModelCheckbox, _ctx.realEstate.noOwnUse]
                    ]),
                    _createElementVNode("span", {
                      class: "q-ml-md checkbox-span",
                      style: _normalizeStyle({ 'font-size': '1.25rem', color: '#444' })
                    }, _toDisplayString(_ctx.attributes.checkboxTextNoOwnUse), 5)
                  ])
                ]),
                (_ctx.realEstate.noOwnUse)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createVNode(_component_q_field, {
                        class: "col-8 col-md-12",
                        filled: "",
                        modelValue: _ctx.realEstate.netRentalIncome,
                        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.realEstate.netRentalIncome) = $event)),
                        label: _ctx.attributes.netRentalIncomeLabel,
                        "stack-label": "",
                        rules: [(v) => !!v || _ctx.requiredFieldText]
                      }, {
                        control: _withCtx(() => [
                          _createVNode(_component_QCurrencyInput, {
                            modelValue: _ctx.realEstate.netRentalIncome,
                            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.realEstate.netRentalIncome) = $event)),
                            class: "q-field__input"
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      }, 8, ["modelValue", "label", "rules"])
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_7, [
                  (_ctx.hasRealEstate)
                    ? (_openBlock(), _createElementBlock("label", _hoisted_8, [
                        _withDirectives(_createElementVNode("input", {
                          class: "larger-checkbox",
                          type: "checkbox",
                          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.realEstate.hasExtraRealEstateCosts) = $event))
                        }, null, 512), [
                          [_vModelCheckbox, _ctx.realEstate.hasExtraRealEstateCosts]
                        ]),
                        _createElementVNode("span", {
                          class: "q-ml-md checkbox-span",
                          style: _normalizeStyle({ 'font-size': '1.25rem', color: '#444' })
                        }, _toDisplayString(_ctx.attributes.additionalCostCheckboxLabel), 5)
                      ]))
                    : _createCommentVNode("", true)
                ]),
                (_ctx.realEstate.hasExtraRealEstateCosts)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _createVNode(_component_q_field, {
                        class: "col-8 col-md-12",
                        filled: "",
                        modelValue: _ctx.realEstate.extraRealEstateCosts,
                        "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.realEstate.extraRealEstateCosts) = $event)),
                        label: _ctx.attributes.additionalCostLabel + ' *',
                        "stack-label": "",
                        rules: [(v) => !!v || _ctx.requiredFieldText]
                      }, {
                        control: _withCtx(() => [
                          _createVNode(_component_QCurrencyInput, {
                            modelValue: _ctx.realEstate.extraRealEstateCosts,
                            "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.realEstate.extraRealEstateCosts) = $event)),
                            class: "q-field__input"
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      }, 8, ["modelValue", "label", "rules"])
                    ]))
                  : _createCommentVNode("", true),
                (!_ctx.editMode)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      _createElementVNode("div", _hoisted_11, [
                        (_ctx.attributes && _ctx.attributes.navigationButtons)
                          ? (_openBlock(), _createBlock(_component_q_btn, {
                              key: 0,
                              onClick: _ctx.onButtonBack,
                              outline: "",
                              color: "primary",
                              label: _ctx.attributes.navigationButtons.backButtonText
                            }, null, 8, ["onClick", "label"]))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_12, [
                        (_ctx.attributes && _ctx.attributes.navigationButtons)
                          ? (_openBlock(), _createBlock(_component_q_btn, {
                              key: 0,
                              type: "submit",
                              color: "accent",
                              label: _ctx.attributes.navigationButtons.nextButtonText
                            }, null, 8, ["label"]))
                          : _createCommentVNode("", true)
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.editMode)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                      _createElementVNode("div", _hoisted_14, [
                        (_ctx.attributes && _ctx.attributes.navigationButtons)
                          ? (_openBlock(), _createBlock(_component_q_btn, {
                              key: 0,
                              type: "submit",
                              outline: "",
                              color: "primary",
                              label: _ctx.attributes.navigationButtons.CancelButtonText
                            }, null, 8, ["label"]))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_15, [
                        (_ctx.attributes && _ctx.attributes.navigationButtons)
                          ? (_openBlock(), _createBlock(_component_q_btn, {
                              key: 0,
                              type: "submit",
                              color: "accent",
                              label: _ctx.attributes.navigationButtons.SaveButtonText
                            }, null, 8, ["label"]))
                          : _createCommentVNode("", true)
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["onSubmit"])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}