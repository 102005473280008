import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3252ca2a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_toolbar = _resolveComponent("q-toolbar")!
  const _component_q_footer = _resolveComponent("q-footer")!

  return (_openBlock(), _createBlock(_component_q_footer, { class: "absolute-bottom footer" }, {
    default: _withCtx(() => [
      _createVNode(_component_q_toolbar, { class: "fit row wrap items-center justify-center" }, {
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            _createElementVNode("a", {
              class: "q-px-md",
              href: _ctx.imprintUrl
            }, _toDisplayString(_ctx.attributes.imprintLabel), 9, _hoisted_1),
            _createElementVNode("a", { href: _ctx.dataProtectionUrl }, _toDisplayString(_ctx.attributes.dataProtectionLabel), 9, _hoisted_2)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}