
import { defineComponent } from "vue";
import StrapiService from "@/api/StrapiService";
import BackendService from "@/api/BackendService";

export default defineComponent({
  data() {
    return {
      personLabels: [],
      persons: [],
    };
  },
  async created() {
    this.getContent();
    await this.initSummary();
  },
  methods: {
    async getContent(): Promise<void> {
      const personContent = await StrapiService.getSummaryContent();
      this.personLabels = personContent.attributes.personalData;
    },
    async initSummary() {
      try {
        const session = await BackendService.getSession();
        this.persons = session.session_data.persons;
      } catch {
        this.$router.push({ path: "error-page" });
      }
    },
    editPersonalData() {
      this.$router.push({ name: "person", params: { edit: true } });
    },
  },
});
