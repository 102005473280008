import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, withModifiers as _withModifiers, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "col-12 col-md-8" }
const _hoisted_3 = { class: "question-content-mb-3" }
const _hoisted_4 = { class: "margin-top-3" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "margin-top-3 row" }
const _hoisted_7 = { class: "col-11 q-ml-md checkbox-span" }
const _hoisted_8 = {
  key: 0,
  class: "error q-py-md"
}
const _hoisted_9 = { class: "q-mt-md row" }
const _hoisted_10 = { class: "col-11 q-ml-md checkbox-span" }
const _hoisted_11 = { class: "q-mt-md" }
const _hoisted_12 = { class: "row" }
const _hoisted_13 = { class: "margin-top-5" }
const _hoisted_14 = { class: "row justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuestionHeader = _resolveComponent("QuestionHeader")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_AddressData = _resolveComponent("AddressData")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_form = _resolveComponent("q-form")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { padding: "" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_3, [
              (_ctx.attributes && _ctx.attributes.questionHead)
                ? (_openBlock(), _createBlock(_component_QuestionHeader, {
                    key: 0,
                    content: _ctx.attributes.questionHead.content,
                    headline: _ctx.attributes.questionHead.headline
                  }, null, 8, ["content", "headline"]))
                : _createCommentVNode("", true)
            ]),
            _createVNode(_component_q_form, {
              ref: "form",
              greedy: "",
              onSubmit: _withModifiers(_ctx.onButtonNext, ["prevent"])
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_q_input, {
                      class: "col-12",
                      filled: "",
                      modelValue: _ctx.email,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
                      type: "email",
                      label: _ctx.attributes.mailInputLabel + ' *',
                      "stack-label": "",
                      rules: [(v) => !!v || _ctx.requiredFieldText]
                    }, null, 8, ["modelValue", "label", "rules"])
                  ])
                ]),
                _createVNode(_component_AddressData, { ref: "address" }, null, 512),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("label", null, [
                    _withDirectives(_createElementVNode("input", {
                      class: "larger-checkbox col-1 q-mt-sm",
                      type: "checkbox",
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.agreedAGBs) = $event))
                    }, null, 512), [
                      [_vModelCheckbox, _ctx.agreedAGBs]
                    ])
                  ]),
                  _createElementVNode("span", _hoisted_7, [
                    (_ctx.attributes.legalCheckbox)
                      ? (_openBlock(), _createBlock(_component_QuestionHeader, {
                          key: 0,
                          content: _ctx.attributes.legalCheckbox
                        }, null, 8, ["content"]))
                      : _createCommentVNode("", true)
                  ])
                ]),
                (!_ctx.agreedAGBs && _ctx.validateLegal)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(_ctx.requiredFieldText), 1))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("label", null, [
                    _withDirectives(_createElementVNode("input", {
                      class: "larger-checkbox col-1 q-mt-sm",
                      type: "checkbox",
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.agreedContact) = $event))
                    }, null, 512), [
                      [_vModelCheckbox, _ctx.agreedContact]
                    ])
                  ]),
                  _createElementVNode("span", _hoisted_10, [
                    (_ctx.attributes.contactCheckbox)
                      ? (_openBlock(), _createBlock(_component_QuestionHeader, {
                          key: 0,
                          content: _ctx.attributes.contactCheckbox
                        }, null, 8, ["content"]))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("label", null, [
                    _withDirectives(_createElementVNode("input", {
                      class: "larger-checkbox",
                      type: "checkbox",
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.wantsToBeContacted) = $event))
                    }, null, 512), [
                      [_vModelCheckbox, _ctx.wantsToBeContacted]
                    ]),
                    _createElementVNode("span", {
                      class: "q-ml-md checkbox-span",
                      style: _normalizeStyle({ 'font-size': '1.25rem', color: '#444' })
                    }, _toDisplayString(_ctx.attributes.consultingCheckbox), 5)
                  ])
                ]),
                _createElementVNode("div", _hoisted_12, [
                  (_ctx.wantsToBeContacted)
                    ? (_openBlock(), _createBlock(_component_q_input, {
                        key: 0,
                        class: "col-12 margin-top-3",
                        filled: "",
                        modelValue: _ctx.phone,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.phone) = $event)),
                        label: _ctx.attributes.phoneLabel + ' *',
                        "stack-label": "",
                        rules: [(v) => !!v || _ctx.requiredFieldText]
                      }, null, 8, ["modelValue", "label", "rules"]))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("div", _hoisted_14, [
                    (_ctx.attributes && _ctx.attributes.navigationButtons)
                      ? (_openBlock(), _createBlock(_component_q_btn, {
                          key: 0,
                          type: "submit",
                          color: "accent",
                          label: _ctx.attributes.navigationButtons.nextButtonText
                        }, null, 8, ["label"]))
                      : _createCommentVNode("", true)
                  ])
                ])
              ]),
              _: 1
            }, 8, ["onSubmit"])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}