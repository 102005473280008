import store from "@/store";
import axios from "axios";
import JwtService from "./JwtService";

const BackendClient = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://localhost:3000"
      : "/backend",
  headers: { "Content-Type": "application/json" },
});

BackendClient.interceptors.request.use(
  async (config) => {
    const token = JwtService.getToken();
    if (token) {
      config.headers.common["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default BackendClient;
