
import LayoutHeader from "./components/LayoutHeader.vue";
import ProgressBar from "./components/ProgressBar.vue";
import LayoutFooter from "./components/LayoutFooter.vue";
import BackendService from "./api/BackendService";
import JwtService from "./api/JwtService";

export default {
  components: { LayoutHeader, ProgressBar, LayoutFooter },
  name: "LayoutDefault",
  async created() {
    this.$store.commit("setToken", null);
    JwtService.destroyToken();
    await this.$store.dispatch("init");
    await BackendService.saveSession(JSON.stringify(this.$store.state));
    await BackendService.getSession();
  },
};
