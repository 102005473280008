
import { defineComponent } from "vue";
import StrapiService from "@/api/StrapiService";
import QuestionHeader from "@/components/QuestionHeader.vue";
import QCurrencyInput from "@/components/QCurrencyInput.vue";
import BackendService from "@/api/BackendService";

export default defineComponent({
  components: {
    QuestionHeader,
    QCurrencyInput,
  },
  data() {
    return {
      attributes: [],
      editMode: false,
      rents: [{ paysRent: true, continuesRenting: true, monthlyRent: 0 }],
      requiredFieldText: "",
    };
  },
  async created() {
    this.getLivingSituationContent();
    await this.initRent();
  },
  methods: {
    async getLivingSituationContent(): Promise<void> {
      this.editMode = this.$route.params.edit;
      const content = await StrapiService.getLivingSituationContent();
      this.attributes = content.attributes;
    },
    async initRent() {
      try {
        const session = await BackendService.getSession();
        this.rents = session.session_data.rents;
        this.requiredFieldText = session.session_data.requiredFieldText;
      } catch {
        this.$router.push({ path: "error-page" });
      }
    },
    async setSessionState() {
      await this.$store.commit("setRents", this.rents);
      try {
        await BackendService.saveSession(JSON.stringify(this.$store.state));
      } catch {
        this.$router.push({ path: "error-page" });
      }
    },
    async onButtonNext() {
      await this.setSessionState();
      if (!this.editMode) {
        this.$router.push({ name: "household" });
      } else {
        this.$router.push({ name: "summary" });
      }
    },
    async onButtonBack() {
      await this.setSessionState();
      this.$router.push({ name: "rent", params: { edit: this.editMode } });
    },
  },
});
