import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "col-12 col-md-8" }
const _hoisted_3 = { class: "question-content-mb-3" }
const _hoisted_4 = { class: "margin-top-3" }
const _hoisted_5 = { class: "row q-col-gutter-sm" }
const _hoisted_6 = { class: "row q-col-gutter-sm q-mt-md" }
const _hoisted_7 = { class: "row items-center justify-end" }
const _hoisted_8 = {
  key: 0,
  class: "row flex justify-between margin-top-5"
}
const _hoisted_9 = { class: "row justify-start" }
const _hoisted_10 = { class: "row justify-end" }
const _hoisted_11 = {
  key: 1,
  class: "row flex justify-between margin-top-5"
}
const _hoisted_12 = { class: "row justify-start" }
const _hoisted_13 = { class: "row justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuestionHeader = _resolveComponent("QuestionHeader")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_date = _resolveComponent("q-date")!
  const _component_q_popup_proxy = _resolveComponent("q-popup-proxy")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_form = _resolveComponent("q-form")!
  const _component_q_page = _resolveComponent("q-page")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createBlock(_component_q_page, { padding: "" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_3, [
              (_ctx.attributes && _ctx.attributes.questionHead)
                ? (_openBlock(), _createBlock(_component_QuestionHeader, {
                    key: 0,
                    content: _ctx.attributes.questionHead.content,
                    headline: _ctx.attributes.questionHead.headline
                  }, null, 8, ["content", "headline"]))
                : _createCommentVNode("", true)
            ]),
            _createVNode(_component_q_form, {
              ref: "form",
              greedy: "",
              onSubmit: _withModifiers(_ctx.onButtonNext, ["prevent"])
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.persons, (person, index) => {
                    return (_openBlock(), _createElementBlock("div", { key: index }, [
                      _createElementVNode("div", _hoisted_5, [
                        (_ctx.salutationOptions)
                          ? (_openBlock(), _createBlock(_component_q_select, {
                              key: 0,
                              class: "col-12 col-md-2",
                              filled: "",
                              modelValue: person.salutation,
                              "onUpdate:modelValue": ($event: any) => ((person.salutation) = $event),
                              options: _ctx.salutationOptions,
                              label: _ctx.attributes.salutationLabel + ' *',
                              "stack-label": "",
                              rules: [
                      (salutationOptions) =>
                        salutationOptions !== 'Bitte wählen' ||
                        _ctx.requiredFieldText,
                    ]
                            }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "label", "rules"]))
                          : _createCommentVNode("", true),
                        (_ctx.titleOptions)
                          ? (_openBlock(), _createBlock(_component_q_select, {
                              key: 1,
                              class: "col-12 col-md-2",
                              filled: "",
                              modelValue: person.title,
                              "onUpdate:modelValue": ($event: any) => ((person.title) = $event),
                              options: _ctx.titleOptions,
                              label: _ctx.attributes.TitleLabel,
                              "stack-label": ""
                            }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "label"]))
                          : _createCommentVNode("", true),
                        _createVNode(_component_q_input, {
                          class: "col-12 col-md-4",
                          filled: "",
                          modelValue: person.firstname,
                          "onUpdate:modelValue": ($event: any) => ((person.firstname) = $event),
                          label: _ctx.attributes.nameLabel + ' *',
                          "stack-label": "",
                          rules: [(v) => !!v || _ctx.requiredFieldText]
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "rules"]),
                        _createVNode(_component_q_input, {
                          class: "col-12 col-md-4",
                          filled: "",
                          modelValue: person.lastname,
                          "onUpdate:modelValue": ($event: any) => ((person.lastname) = $event),
                          label: _ctx.attributes.lastnameLabel + ' *',
                          "stack-label": "",
                          rules: [(v) => !!v || _ctx.requiredFieldText]
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "rules"])
                      ]),
                      _createElementVNode("div", _hoisted_6, [
                        _createVNode(_component_q_input, {
                          class: "col-12 col-md-4",
                          filled: "",
                          "stack-label": "",
                          "bottom-slots": "",
                          modelValue: person.birthDate,
                          "onUpdate:modelValue": ($event: any) => ((person.birthDate) = $event),
                          placeholder: "'TT.MM.JJJJ'",
                          mask: "##.##.####",
                          label: _ctx.attributes.birthdayLabel + ' *',
                          rules: [(v) => !!v || _ctx.requiredFieldText]
                        }, {
                          append: _withCtx(() => [
                            _createVNode(_component_q_icon, {
                              name: "far fa-calendar-alt",
                              class: "text-primary cursor-pointer"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_q_popup_proxy, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_q_date, {
                                      modelValue: person.birthDate,
                                      "onUpdate:modelValue": ($event: any) => ((person.birthDate) = $event),
                                      minimal: "",
                                      mask: "DD.MM.YYYY",
                                      "default-view": "Years",
                                      locale: _ctx.myLocale
                                    }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("div", _hoisted_7, [
                                          _withDirectives(_createVNode(_component_q_btn, {
                                            label: _ctx.attributes.datePickerOkButtonLabel,
                                            color: "primary",
                                            flat: ""
                                          }, null, 8, ["label"]), [
                                            [_directive_close_popup]
                                          ])
                                        ])
                                      ]),
                                      _: 2
                                    }, 1032, ["modelValue", "onUpdate:modelValue", "locale"])
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1032, ["modelValue", "onUpdate:modelValue", "label", "rules"]),
                        _createVNode(_component_q_select, {
                          class: "col-12 col-md-8",
                          filled: "",
                          modelValue: person.nationality,
                          "onUpdate:modelValue": ($event: any) => ((person.nationality) = $event),
                          options: _ctx.nationalityOptions,
                          label: _ctx.attributes.nationalityLabel + ' *',
                          "stack-label": "",
                          rules: [
                      (nationalityOptions) =>
                        nationalityOptions !== '-- Bitte wählen --' ||
                        _ctx.requiredFieldText,
                    ]
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "label", "rules"])
                      ])
                    ]))
                  }), 128))
                ]),
                (!_ctx.editMode)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createElementVNode("div", _hoisted_9, [
                        (_ctx.attributes && _ctx.attributes.navigationButtons)
                          ? (_openBlock(), _createBlock(_component_q_btn, {
                              key: 0,
                              onClick: _ctx.onButtonBack,
                              outline: "",
                              color: "primary",
                              label: _ctx.attributes.navigationButtons[0].backButtonText
                            }, null, 8, ["onClick", "label"]))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_10, [
                        (_ctx.attributes && _ctx.attributes.navigationButtons)
                          ? (_openBlock(), _createBlock(_component_q_btn, {
                              key: 0,
                              color: "accent",
                              type: "submit",
                              label: _ctx.attributes.navigationButtons[0].nextButtonText
                            }, null, 8, ["label"]))
                          : _createCommentVNode("", true)
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.editMode)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                      _createElementVNode("div", _hoisted_12, [
                        (_ctx.attributes && _ctx.attributes.navigationButtons)
                          ? (_openBlock(), _createBlock(_component_q_btn, {
                              key: 0,
                              type: "submit",
                              outline: "",
                              color: "primary",
                              label: _ctx.attributes.navigationButtons[0].CancelButtonText
                            }, null, 8, ["label"]))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_13, [
                        (_ctx.attributes && _ctx.attributes.navigationButtons)
                          ? (_openBlock(), _createBlock(_component_q_btn, {
                              key: 0,
                              color: "accent",
                              type: "submit",
                              label: _ctx.attributes.navigationButtons[0].SaveButtonText
                            }, null, 8, ["label"]))
                          : _createCommentVNode("", true)
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["onSubmit"])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}