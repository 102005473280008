
import { defineComponent } from "vue";
import StrapiService from "@/api/StrapiService";
import QuestionHeader from "@/components/QuestionHeader.vue";
import BackendService from "@/api/BackendService";

export default defineComponent({
  components: {
    QuestionHeader,
  },
  data() {
    return {
      attributes: [],
      editMode: false,
      validate: false,
      realEstate: {
        street: "",
        houseNumber: "",
        zip: "",
        city: "",
        extraRealEstateCosts: 0,
        hasExtraRealEstateCosts: false,
      },
      addRealEstate: false,
      hasRealEstate: "initial",
      requiredFieldText: "",
    };
  },
  async created() {
    await this.getEntryContent();
    await this.initEntry();
  },
  methods: {
    async getEntryContent(): Promise<void> {
      this.editMode = this.$route.params.edit;
      const content = await StrapiService.getEntryContent();
      this.attributes = content.attributes;
      await this.$store.dispatch("init");
      await BackendService.saveSession(JSON.stringify(this.$store.state));
      await BackendService.getSession();
    },
    async initEntry() {
      try {
        if (this.$store.state.token) {
          const session = await BackendService.getSession();
          this.realEstate = session.session_data.realEstate;
          this.hasRealEstate = session.session_data.hasRealEstate;
          this.addRealEstate = session.session_data.addRealEstate;
          this.requiredFieldText = session.session_data.requiredFieldText;
        }
      } catch {
        this.$router.push({ path: "error-page" });
      }
    },
    resetRealEstateAddress() {
      this.realEstate.street = "";
      this.realEstate.houseNumber = "";
      this.realEstate.zip = "";
      this.realEstate.city = "";
    },
    async onButtonNext() {
      if (!this.addRealEstate) {
        this.resetRealEstateAddress();
      }
      if (!this.hasRealEstate) {
        this.resetRealEstateAddress();
        this.realEstate.extraRealEstateCosts = 0;
        this.addRealEstate = false;
        this.realEstate.hasExtraRealEstateCosts = false;
      }
      if (this.hasRealEstate === "initial") {
        this.validate = true;
      } else if (!this.editMode) {
        await this.setSessionState();
        await this.saveSession();
        this.$router.push({ name: "key-data" });
      } else {
        await this.setSessionState();
        await this.saveSession();
        this.$router.push({ name: "summary" });
      }
    },
    async setHasRealEstate(value) {
      this.hasRealEstate = value;
      await this.$store.commit("setHasRealEstate", value);
    },
    async setSessionState() {
      await this.$store.commit("setAddRealEstate", this.addRealEstate);
      await this.$store.commit("setRealEstate", this.realEstate);
    },
    async saveSession() {
      try {
        await BackendService.saveSession(JSON.stringify(this.$store.state));
      } catch {
        this.$router.push({ path: "error-page" });
      }
    },
  },
});
