import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "header-wrapper" }
const _hoisted_2 = { class: "q-py-md" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.attributes && _ctx.logoURL)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: _ctx.imageURL + _ctx.logoURL
          }, null, 8, _hoisted_3))
        : _createCommentVNode("", true)
    ])
  ]))
}