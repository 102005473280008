
import { defineComponent } from "vue";
import StrapiService from "@/api/StrapiService";
import QuestionHeader from "@/components/QuestionHeader.vue";
import QCurrencyInput from "@/components/QCurrencyInput.vue";
import BackendService from "@/api/BackendService";

export default defineComponent({
  components: {
    QuestionHeader,
    QCurrencyInput,
  },
  data() {
    return {
      attributes: [],
      employmentOptions: [],
      extraIncomeOptions: [],
      editMode: false,
      persons: [
        {
          employment: "-- Bitte wählen --",
        },
      ],
      incomes: [
        {
          income: 0,
          hasExtraIncome: false,
          listExtraIncome: [
            {
              extraSource: "-- Bitte wählen --",
              extraIncome: 0,
            },
          ],
        },
      ],
      sumExtraIncome: 0,
      requiredFieldText: "",
    };
  },
  async created() {
    this.getIncomeContent();
    await this.initIncome();
  },
  methods: {
    async getIncomeContent(): Promise<void> {
      this.editMode = this.$route.params.edit;
      const content = await StrapiService.getIncomeContent();
      this.attributes = content.attributes;

      let employmentOptions = [];
      this.attributes.employment.data.forEach((element) => {
        employmentOptions.push(element.attributes.name);
        this.employmentOptions = employmentOptions;
      });

      let extraIncomeOptions = [];
      this.attributes.incomeSources.data.forEach((element) => {
        extraIncomeOptions.push(element.attributes.name);
        this.extraIncomeOptions = extraIncomeOptions;
      });
    },
    async initIncome() {
      try {
        const session = await BackendService.getSession();
        this.persons = session.session_data.persons;
        this.incomes = session.session_data.incomes;
        this.sumExtraIncome = session.session_data.sumExtraIncome;
        this.requiredFieldText = session.session_data.requiredFieldText;
      } catch {
        this.$router.push({ path: "error-page" });
      }
    },
    calcSumExtraIncome() {
      let calcSum = this.incomes[0].listExtraIncome
        .map((extra) => extra.extraIncome)
        .reduce((prev, curr) => Number(prev) + Number(curr), 0);
      this.$store.commit("setSumExtraIncome", calcSum);
    },
    async setSessionState() {
      await this.$store.commit("setPersons", this.persons);
      await this.$store.commit("setIncomes", this.incomes);
      try {
        await BackendService.saveSession(JSON.stringify(this.$store.state));
      } catch {
        this.$router.push({ path: "error-page" });
      }
    },
    async onButtonNext() {
      if (!this.hasExtraIncome) {
        this.listExtraIncome = [
          {
            extraSource: "-- Bitte wählen --",
            extraIncome: 0,
          },
        ];
      }
      this.calcSumExtraIncome();
      await this.setSessionState();
      if (!this.editMode) {
        this.$router.push({ name: "costs" });
      } else {
        this.$router.push({ name: "summary" });
      }
    },
    async onButtonBack() {
      await this.setSessionState();
      this.$router.push({ name: "household" });
    },
    addRow() {
      this.incomes[0]?.listExtraIncome.push({
        extraSource: "-- Bitte wählen --",
        extraIncome: 0,
      });
    },
    deleteRow(index) {
      this.incomes[0]?.listExtraIncome.splice(index, 1);
    },
  },
});
