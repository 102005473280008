
import { defineComponent } from "vue";
import StrapiService from "@/api/StrapiService";
import QuestionHeader from "@/components/QuestionHeader.vue";
import QCurrencyInput from "@/components/QCurrencyInput.vue";
import BackendService from "@/api/BackendService";

export default defineComponent({
  components: {
    QuestionHeader,
    QCurrencyInput,
  },
  data() {
    return {
      attributes: [],
      creditTypeOptions: [],
      editMode: false,
      myLocale: {
        days: [
          "Montag",
          "Dienstag",
          "Mittwoch",
          "Donnerstag",
          "Freitag",
          "Samstag",
          "Sonntag",
        ],
        daysShort: ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"],
        months: [
          "Januar",
          "Februar",
          "März",
          "April",
          "Mai",
          "Juni",
          "Juli",
          "August",
          "September",
          "Oktober",
          "November",
          "Dezember",
        ],
        monthsShort: [
          "Jan.",
          "Feb.",
          "März",
          "Apr.",
          "Mai",
          "Jun.",
          "Jul.",
          "Aug.",
          "Sept.",
          "Okt.",
          "Nov.",
          "Dez.",
        ],
        firstDayOfWeek: 0,
      },
      expenses: [],
      sumCredits: 0,
      requiredFieldText: "",
    };
  },
  async created() {
    this.getCreditsContent();
    await this.initAddCredits();
  },
  methods: {
    async getCreditsContent(): Promise<void> {
      const content = await StrapiService.getCreditsContent();
      this.attributes = content.attributes;

      let creditTypeOptions = [];
      this.attributes.creditOptions.data.forEach((element) => {
        creditTypeOptions.push(element.attributes.creditOption);
        this.creditTypeOptions = creditTypeOptions;
      });
    },
    async initAddCredits() {
      try {
        const session = await BackendService.getSession();
        this.expenses = session.session_data.expenses;
        this.sumCredits = session.session_data.sumCredits;
        this.requiredFieldText = session.session_data.requiredFieldText;
      } catch {
        this.$router.push({ path: "error-page" });
      }
    },
    async calcSumCredits() {
      if (this.expenses[0]?.listCredits) {
        let calcSum = this.expenses[0].listCredits
          .map((credit) => credit.monthlyRate)
          .reduce((prev, curr) => Number(prev) + Number(curr), 0);
        this.sumCredits = calcSum;
      }
      await this.$store.commit("setSumCredits", this.sumCredits);
    },
    async addRow() {
      this.expenses[0]?.listCredits.push({
        type: "-- Bitte wählen --",
        monthlyRate: 0,
        remainingDebt: 0,
        endDate: "",
      });
      await this.$store.commit("setListCredits", this.expenses[0]?.listCredits);
    },
    async deleteRow(index) {
      this.expenses[0]?.listCredits.splice(index, 1);
      await this.$store.commit("setListCredits", this.expenses[0]?.listCredits);
    },
  },
});
