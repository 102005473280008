import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "col-12 col-md-8" }
const _hoisted_3 = {
  key: 0,
  class: "question-content-mb-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuestionHeader = _resolveComponent("QuestionHeader")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { padding: "" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", null, [
            (_ctx.email)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  (_ctx.attributes && _ctx.attributes.questionHead)
                    ? (_openBlock(), _createBlock(_component_QuestionHeader, {
                        key: 0,
                        content: _ctx.attributes.questionHead.content + ' ' + _ctx.email,
                        headline: _ctx.attributes.questionHead.headline
                      }, null, 8, ["content", "headline"]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ]),
    _: 1
  }))
}