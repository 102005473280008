
import { defineComponent } from "vue";
import StrapiService from "@/api/StrapiService";
import QuestionHeader from "@/components/QuestionHeader.vue";
import QCurrencyInput from "@/components/QCurrencyInput.vue";
import QCurrencyInputPercentage from "@/components/QCurrencyInputPercentage.vue";
import RateDialog from "@/components/RateDialog.vue";
import openURL from 'quasar/src/utils/open-url.js';;
import BackendService from "@/api/BackendService";

export default defineComponent({
  components: {
    QuestionHeader,
    QCurrencyInput,
    QCurrencyInputPercentage,
    RateDialog,
  },
  data() {
    return {
      attributes: [],
      config: [],
      toggleDialogPurchaseCosts: false,
      purchaseBuffer: 0,
      copyTaxes: 0,
      copyBrokerageFee: 0,
      copyNotaryFee: 0,
      maxLoanAmount: 0,
      maxFinancialFramework: 0,
      initAdditionalPurchaseCosts: 0,
      calcAdditionalPurchaseCosts: 0,
      calcMaxPurchasePrice: 0,
      toContact: false,
      equityBuffer: 0,
      maxMonthlyFinancingRate: 0,
      sumIncome: 0,
      sumCosts: 0,
      equity: 0,
      extraRealEstateCosts: 0,
      price: 0,
      income: 0,
      insureanceCost: 0,
      numberCars: 0,
      numberPersons: 1,
      numberChildren: 0,
      maintenanceCost: 0,
      monthlyRent: 0,
      taxes: 0,
      brokerageFee: 0,
      notaryFee: 0,
      sumExtraIncome: 0,
      sumCredits: 0,
      listExtraIncome: [],
      monthlyAdditionalCosts: 0,
      livingSpaceInSm: "",
      netRentalIncome: 0,
      noOwnUse: false,
    };
  },
  computed: {
    toggleDialogRate: {
      get() {
        return this.$store.state.toggleDialogRate;
      },
      set(value) {
        this.$store.commit("setToggleDialogRate", value);
      },
    },
  },
  async created() {
    await this.initData();
    await this.getEvaluationContent();
    this.initCopyFees();
    this.calcMaxMonthlyFinancingRate();
    this.calcMaxLoanAmount();
    this.calcMaxFinancialFramework();
    this.calcInitAdditionalPurchaseCosts();
    this.calcPurchaseBuffer();
    this.checkBuffer();
  },
  methods: {
    async getEvaluationContent(): Promise<void> {
      const content = await StrapiService.getEvaluationContent();
      this.attributes = content.attributes;
      const config = await StrapiService.getConfigContent();
      this.config = config.attributes;

      this.equityBuffer = config.attributes.equityBuffer;
    },
    async initData() {
      try {
        const session = await BackendService.getSession();
        const data = session.session_data;
        this.equity = data.equity;
        this.extraRealEstateCosts = data.realEstate.extraRealEstateCosts;
        this.price = data.realEstate.price;
        this.income = data.incomes[0].income;
        this.insureanceCost = data.expenses[0].insureanceCost;
        this.numberCars = data.numberCars;
        this.numberPersons = data.numberPersons;
        this.numberChildren = data.numberChildren;
        this.maintenanceCost = data.expenses[0].maintenanceCost;
        this.monthlyRent = data.rents[0].monthlyRent;
        this.taxes = Number(data.fees.taxes);
        this.brokerageFee = Number(data.fees.brokerageFee);
        this.notaryFee = Number(data.fees.notaryFee);
        this.sumExtraIncome = data.sumExtraIncome;
        this.sumCredits = data.sumCredits;
        this.listExtraIncome = data.incomes[0].listExtraIncome;
        this.maxFinancialFramework = data.calculation.maxFinancialFramework;
        this.sumIncome = data.calculation.sumIncome;
        this.sumCosts = data.calculation.sumCosts;
        this.livingSpaceInSm = Number(data.realEstate.livingSpaceInSm);
        this.netRentalIncome = Number(data.realEstate.netRentalIncome);
        this.maxLoanAmount = data.calculation.maxLoanAmount;
        this.maxMonthlyFinancingRate = data.calculation.maxMonthlyFinancingRate;
        this.maxPurchasePrice = data.calculation.maxPurchasePrice;
        this.monthlyAdditionalCosts = data.calculation.monthlyAdditionalCosts;
        this.purchaseCosts = data.calculation.purchaseCosts;
        this.noOwnUse = data.realEstate.noOwnUse;
      } catch {
        this.$router.push({ path: "error-page" });
      }
    },
    initCopyFees() {
      this.copyTaxes = this.taxes;
      this.copyBrokerageFee = this.brokerageFee;
      this.copyNotaryFee = this.notaryFee;
    },
    calcMaxMonthlyFinancingRate() {
      const income = Number(this.income);
      const insureanceCost = Number(this.insureanceCost);
      const maintenanceCost = Number(this.maintenanceCost);
      const rent = Number(this.monthlyRent);
      const credits = Number(this.sumCredits);
      const costsCar = this.config.costsCar;
      const insureanceBuffer = this.config.insureanceBuffer;
      const generalBuffer = this.config.generalBuffer;
      const calcPercentFirstPerson = this.config.calcPercentFirstPerson;
      const calcPercentMorePerson = this.config.calcPercentMorePerson;
      const calcPercentChildren = this.config.calcPercentChildren;
      const minCalcFirstPerson = this.config.minCalcFirstPerson;
      const maxCalcFirstPerson = this.config.maxCalcFirstPerson;
      const minCalcMorePerson = this.config.minCalcMorePerson;
      const maxCalcMorePerson = this.config.maxCalcMorePerson;
      const minCalcChildren = this.config.minCalcChildren;
      const maxCalcChildren = this.config.maxCalcChildren;
      const percentRentExtraIncome = this.config.percentRentExtraIncome;
      let cars = this.config.minCarForCalc; // min. one car for calculation
      const livingSpaceInSmCost = this.config.livingSpacePerSmCosts;

      if (this.numberCars >= 1) {
        cars = this.numberCars;
      }

      let extraIncome = this.listExtraIncome
        .filter((income) => income.extraSource !== "Mieteinnahmen")
        .map((extra) => extra.extraIncome)
        .reduce((prev, curr) => Number(prev) + Number(curr), 0);

      let rentExtraIncome = this.listExtraIncome
        .filter((income) => income.extraSource === "Mieteinnahmen")
        .map((extra) => extra.extraIncome)
        .reduce((prev, curr) => Number(prev) + Number(curr), 0);

      let sumIncome = income + extraIncome;

      let calcFirstPerson = calcPercentFirstPerson * sumIncome;
      if (calcFirstPerson > maxCalcFirstPerson) {
        calcFirstPerson = maxCalcFirstPerson;
      }
      if (calcFirstPerson < minCalcFirstPerson) {
        calcFirstPerson = minCalcFirstPerson;
      }

      let calcMorePersons = calcPercentMorePerson * sumIncome;
      if (calcMorePersons > maxCalcMorePerson) {
        calcMorePersons = maxCalcMorePerson;
      }
      if (calcMorePersons < minCalcMorePerson) {
        calcMorePersons = minCalcMorePerson;
      }

      let calcChildren = calcPercentChildren * sumIncome;
      if (calcChildren > maxCalcChildren) {
        calcChildren = maxCalcChildren;
      }
      if (calcChildren < minCalcChildren) {
        calcChildren = minCalcChildren;
      }

      let calcMorePersonWithoutChildren =
        this.numberPersons - 1 - this.numberChildren;

      let calcLivingSpaceCosts = this.livingSpaceInSm * livingSpaceInSmCost;

      let sumCosts =
        insureanceBuffer +
        insureanceCost +
        maintenanceCost +
        credits +
        cars * costsCar +
        calcFirstPerson +
        calcMorePersonWithoutChildren * calcMorePersons +
        this.numberChildren * calcChildren +
        generalBuffer +
        rent;

      if (!this.noOwnUse) {
        sumCosts += calcLivingSpaceCosts;
      }

      sumIncome +=
        (rentExtraIncome + this.netRentalIncome) * percentRentExtraIncome;

      let monthlyRate = sumIncome - sumCosts;

      this.maxMonthlyFinancingRate = monthlyRate;
      this.sumIncome = sumIncome;
      this.sumCosts = sumCosts;
      this.$store.commit("setSumIncome", sumIncome);
      this.$store.commit("setSumCosts", sumCosts);
      this.$store.commit("setMaxMonthlyFinancingRate", monthlyRate);
    },
    calcMaxLoanAmount() {
      if (this.maxMonthlyFinancingRate > 0) {
        const interestRate = this.config.interestRate;
        const topInterestRepaymentRate = this.config.topInterestRepaymentRate;
        this.maxLoanAmount =
          (this.maxMonthlyFinancingRate * 100 * 12) /
          (interestRate + topInterestRepaymentRate);
      } else {
        this.maxLoanAmount = 0;
      }
    },
    calcMaxFinancialFramework() {
      const calcSum = Number(this.maxLoanAmount) + Number(this.equity);
      this.maxFinancialFramework = calcSum;
    },
    setToggleDialogRate() {
      if (this.$refs.dialogRate) {
        this.$refs.dialogRate.init();
        this.$refs.dialogRate.monthlyAdditionalCosts =
          this.monthlyAdditionalCosts;
      }
      this.toggleDialogRate = true;
    },
    checkForButton(purchaseCosts) {
      this.toContact = !(this.equity >= purchaseCosts * this.equityBuffer);
    },
    calcCalcAdditionalPurchaseCosts() {
      this.calcAdditionalPurchaseCosts =
        (this.maxFinancialFramework - this.extraRealEstateCosts) *
        ((Number(this.copyTaxes) +
          Number(this.copyBrokerageFee) +
          Number(this.copyNotaryFee)) /
          100);
      this.checkForButton(this.calcAdditionalPurchaseCosts);
    },
    calcInitAdditionalPurchaseCosts() {
      this.initAdditionalPurchaseCosts =
        this.price *
        ((Number(this.copyTaxes) +
          Number(this.copyBrokerageFee) +
          Number(this.copyNotaryFee)) /
          100);
      this.checkForButton(this.initAdditionalPurchaseCosts);
    },
    calcPurchaseBuffer() {
      this.purchaseBuffer =
        this.maxFinancialFramework -
        this.initAdditionalPurchaseCosts -
        this.extraRealEstateCosts -
        this.price;
    },
    checkBuffer() {
      if (this.purchaseBuffer < 0) {
        this.calcCalcAdditionalPurchaseCosts();
        this.calcMaxPurchasePrice =
          this.maxFinancialFramework -
          this.extraRealEstateCosts -
          this.calcAdditionalPurchaseCosts;
      }
    },
    updatePurchaseCosts() {
      this.calcInitAdditionalPurchaseCosts();
      this.calcPurchaseBuffer();
      this.checkBuffer();
      this.toggleDialogPurchaseCosts = false;
    },
    updateMonthlyFinancingRate(newRate, addCost) {
      this.monthlyAdditionalCosts = addCost;
      this.maxMonthlyFinancingRate = newRate;
      this.calcMaxLoanAmount();
      this.calcMaxFinancialFramework();
      this.updatePurchaseCosts();
      this.toggleDialogRate = false;
    },
    onButtonNext() {
      const minLoan = this.config.minLoan;
      const calculation = {
        maxFinancialFramework: this.maxFinancialFramework,
        maxLoanAmount: this.maxLoanAmount,
        maxMonthlyFinancingRate: this.maxMonthlyFinancingRate,
        maxPurchasePrice:
          this.calcMaxPurchasePrice > 0
            ? this.calcMaxPurchasePrice
            : this.price,
        monthlyAdditionalCosts: this.monthlyAdditionalCosts,
        purchaseCosts:
          this.calcAdditionalPurchaseCosts > 0
            ? this.calcAdditionalPurchaseCosts
            : this.initAdditionalPurchaseCosts,
        sumCosts: this.sumCosts,
        sumIncome: this.sumIncome,
        isCalcPurchasePrice: this.calcMaxPurchasePrice > 0,
      };
      this.$store.commit("setCalculation", calculation);
      this.$store.commit("setFees", {
        taxes: this.copyTaxes,
        brokerageFee: this.copyBrokerageFee,
        notaryFee: this.copyNotaryFee,
      });
      this.saveSession();
      if (this.maxLoanAmount > minLoan && !this.toContact) {
        this.$router.push({ name: "final" });
      } else {
        this.$router.push({ name: "modulation" });
      }
    },
    onButtonBack() {
      this.$router.push({ name: "summary" });
    },
    onToContact() {
      openURL(this.attributes.toContactUrl);
    },
    async saveSession() {
      try {
        await BackendService.saveSession(JSON.stringify(this.$store.state));
      } catch {
        this.$router.push({ path: "error-page" });
      }
    },
  },
});
