
import { defineComponent } from "vue";
import StrapiService from "@/api/StrapiService";

export default defineComponent({
  data() {
    return {
      attributes: [],
      logoURL: "",
      imageURL: "",
    };
  },
  created() {
    this.getConfigContent();
  },
  methods: {
    async getConfigContent(): Promise<void> {
      const content = await StrapiService.getConfigContent();
      const attributes = content.attributes;
      this.attributes = attributes;
      this.logoURL = attributes.Logo.data.attributes.url;
      this.imageURL = attributes.imageUrl;
      this.$store.commit("setBaseUrl", attributes.baseUrl);
      this.$store.commit("setRequiredFieldText", attributes.requiredFieldText);
    },
  },
});
