
import { defineComponent } from "vue";
import StrapiService from "@/api/StrapiService";
import QuestionHeader from "@/components/QuestionHeader.vue";
import QCurrencyInput from "@/components/QCurrencyInput.vue";
import BackendService from "@/api/BackendService";

export default defineComponent({
  components: {
    QuestionHeader,
    QCurrencyInput,
  },
  data() {
    return {
      attributes: [],
      editMode: false,
      validateInsureance: false,
      validateMaintenance: false,
      expenses: [
        {
          hasInsureanceCost: "initial",
          insureanceCost: 0,
          hasMaintenanceCost: "initial",
          maintenanceCost: 0,
          hasCredits: "initial",
          listCredits: [
            {
              type: "",
              monthlyRate: 0,
              remainingDebt: 0,
              endDate: "",
            },
          ],
        },
      ],
      requiredFieldText: "",
    };
  },
  async created() {
    this.getCostContent();
    await this.initCosts();
  },
  methods: {
    async getCostContent(): Promise<void> {
      this.editMode = this.$route.params.edit;
      const content = await StrapiService.getCostContent();
      this.attributes = content.attributes;
    },
    async initCosts() {
      try {
        const session = await BackendService.getSession();
        this.expenses = session.session_data.expenses;
        this.requiredFieldText = session.session_data.requiredFieldText;
      } catch {
        this.$router.push({ path: "error-page" });
      }
    },
    async setSessionState() {
      await this.$store.commit("setExpenses", this.expenses);
      try {
        await BackendService.saveSession(JSON.stringify(this.$store.state));
      } catch {
        this.$router.push({ path: "error-page" });
      }
    },
    async onButtonNext() {
      if (this.expenses[0].hasMaintenanceCost === "initial") {
        this.validateMaintenance = true;
      }
      if (this.expenses[0].hasInsureanceCost === "initial") {
        this.validateInsureance = true;
      }
      if (!this.validateInsureance && !this.validateMaintenance) {
        if (!this.expenses[0].hasMaintenanceCost) {
          this.expenses[0].maintenanceCost = 0;
        }
        if (!this.expenses[0].hasInsureanceCost) {
          this.expenses[0].insureanceCost = 0;
        }
        await this.setSessionState();
        if (!this.editMode) {
          this.$router.push({ name: "credits" });
        }
        if (this.editMode) {
          this.$router.push({ name: "summary" });
        }
      }
    },
    async onButtonBack() {
      await this.setSessionState();
      this.$router.push({ name: "income" });
    },
    setHasInsureanceCost(value) {
      this.expenses[0].hasInsureanceCost = value;
    },
    setHasMaintenanceCost(value) {
      this.expenses[0].hasMaintenanceCost = value;
    },
  },
});
