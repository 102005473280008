
import { defineComponent } from "vue";
import StrapiService from "@/api/StrapiService";
import QuestionHeader from "@/components/QuestionHeader.vue";
import BackendService from "@/api/BackendService";

export default defineComponent({
  components: {
    QuestionHeader,
  },
  data() {
    return {
      attributes: [],
      editMode: false,
      validate: false,
      rents: [
        {
          paysRent: true,
          continuesRenting: "initial",
          monthlyRent: 0,
        },
      ],
    };
  },
  async created() {
    this.getLivingSituationContent();
    await this.initRent();
  },
  methods: {
    async getLivingSituationContent(): Promise<void> {
      this.editMode = this.$route.params.edit;
      const content = await StrapiService.getLivingSituationContent();
      this.attributes = content.attributes;
    },
    async initRent() {
      try {
        const session = await BackendService.getSession();
        this.rents = session.session_data.rents;
      } catch {
        this.$router.push({ path: "error-page" });
      }
    },
    setContinuesRenting(value) {
      this.rents[0].continuesRenting = value;
    },
    async setSessionState() {
      await this.$store.commit("setRents", this.rents);
      try {
        await BackendService.saveSession(JSON.stringify(this.$store.state));
      } catch {
        this.$router.push({ path: "error-page" });
      }
    },
    async onButtonNext() {
      if (this.rents[0].continuesRenting === "initial") {
        this.validate = true;
      }
      if (this.rents[0].continuesRenting === true) {
        await this.setSessionState();
        this.$router.push({
          name: "monthly-rent",
          params: { edit: this.editMode },
        });
      }
      if (!this.rents[0].continuesRenting) {
        this.rents[0] = {
          paysRent: true,
          continuesRenting: false,
          monthlyRent: 0,
        };
        await this.setSessionState();
        if (!this.editMode) {
          this.$router.push({ name: "household" });
        } else {
          this.$router.push({ name: "summary" });
        }
      }
    },
    async onButtonBack() {
      await this.setSessionState();
      this.$router.push({
        name: "living-situation",
        params: { edit: this.editMode },
      });
    },
  },
});
