
import { defineComponent } from "vue";
import StrapiService from "@/api/StrapiService";
import QCurrencyInput from "@/components/QCurrencyInput.vue";
import BackendService from "@/api/BackendService";

export default defineComponent({
  components: {
    QCurrencyInput,
  },
  data() {
    return {
      expensesContent: [],
      hasInsureanceCost: "initial",
      insureanceCost: 0,
      hasMaintenanceCost: "initial",
      maintenanceCost: 0,
      hasCredits: "initial",
      listCredits: [],
      yesLabel: "",
      noLabel: "",
    };
  },
  async created() {
    this.getContent();
    await this.initSummary();
  },
  methods: {
    async getContent(): Promise<void> {
      const expensesContent = await StrapiService.getSummaryContent();
      this.expensesContent = expensesContent.attributes.expenses;
      this.yesLabel = expensesContent.attributes.yesLabel;
      this.noLabel = expensesContent.attributes.noLabel;
    },
    async initSummary() {
      try {
        const session = await BackendService.getSession();
        const summaryExpenses = session.session_data.expenses[0];
        this.hasInsureanceCost = summaryExpenses.hasInsureanceCost;
        this.insureanceCost = summaryExpenses.insureanceCost;
        this.hasMaintenanceCost = summaryExpenses.hasMaintenanceCost;
        this.maintenanceCost = summaryExpenses.maintenanceCost;
        this.hasCredits = summaryExpenses.hasCredits;
        this.listCredits = summaryExpenses.listCredits;
      } catch {
        this.$router.push({ path: "error-page" });
      }
    },
    editCosts() {
      this.$router.push({ name: "costs", params: { edit: true } });
    },
    editCredits() {
      this.$router.push({ name: "credits", params: { edit: true } });
    },
  },
});
